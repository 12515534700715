.SsoInitiate {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.SsoInitiate-title {
  display: block;
  color: #273941;
  font-weight: bolder;
}
.SsoInitiate-subtitle {
  display: block;
  color: #273941;
  font-weight: bold;
}
