.Proposals-header {
  padding-bottom: 5px;
  height: auto;
  margin: 0 1rem;
}
.Proposals-header h1 {
  font-size: 2rem;
  font-weight: bold;
  color: #273941;
  margin: 0;
}
@media only screen and (min-width:992px) {
  .Proposals-header h1 {
    font-size: 50px;
  }
}
.Proposals-buttonrow {
  margin: 1rem;
  text-align: center;
}
.Proposals-buttonrow .ant-btn {
  border-radius: 5px;
  color: #FFF;
  background-color: #147F74;
  border-color: #147F74;
  font-weight: bold;
  font-size: 16px;
  height: 47px;
}
.Proposals-buttonrow .ant-btn:hover,
.Proposals-buttonrow .ant-btn:focus,
.Proposals-buttonrow .ant-btn:active {
  text-decoration: none;
  outline: 0;
}
.Proposals-empty-row {
  padding: 0 3rem;
  margin: 50% 0;
}
.Proposals-empty-row .Proposals-empty-title {
  display: block;
  font-size: 1.5rem;
  font-weight: bold;
  color: #5a5c5e;
}
.Proposals-empty-row .Proposals-empty-description {
  font-size: 1rem;
  color: #a9a9a9;
}
@media only screen and (min-width:992px) {
  .Proposals-empty-row {
    margin: 25%;
  }
}
@media only screen and (min-width:1200px) {
  .Proposals-empty-row {
    margin: 10%;
  }
}
.Proposals-proposalcard-row {
  margin: 0 1rem;
}
.Proposals-proposalcard-row .Proposals-proposalcard {
  margin-bottom: 1rem;
  padding-bottom: 9px;
}
.Proposals-proposalcard-row .Proposals-proposalcard .Proposals-proposalcard-header {
  padding: 9px 15px;
  color: #a9a9a9;
  line-height: 18px;
}
@media only screen and (min-width:992px) {
  .Proposals-proposalcard-row .Proposals-proposalcard .Proposals-proposalcard-header {
    font-size: 16px;
  }
}
.Proposals-proposalcard-row .Proposals-proposalcard .Proposals-proposalcard-body {
  margin: 0 1rem;
  font-size: 18px;
  font-weight: bold;
  line-height: 22px;
  color: #000;
}
.Proposals-proposalcard-row .Proposals-proposalcard .open {
  color: #00c171;
}
.Proposals-proposalcard-row .Proposals-proposalcard .rejected {
  color: #ff2068;
}
.Proposals-reviewed-divider {
  margin: 1rem;
  margin-top: 0;
}
.Proposals-reviewed-divider .ant-divider-horizontal.ant-divider-with-text {
  text-align: center;
  margin-top: 0;
  color: #a9a9a9;
}
.Proposals-reviewed-divider .ant-divider-horizontal.ant-divider-with-text .reviewed-count {
  background-color: darkgray;
  border: darkgray 1px solid;
  border-radius: 10px;
  padding: 0px 10px;
  color: #FFF;
}
