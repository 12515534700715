.cohort-selector .cohort-selector-body .ant-input-affix-wrapper {
  height: 52px;
  margin-bottom: 1rem;
  border-radius: 5px;
  background-color: #d9d9d9;
  border: 2px solid #d9d9d9;
}
.cohort-selector .cohort-selector-body .ant-input-affix-wrapper svg {
  height: 20px;
  width: 20px;
}
.cohort-selector .cohort-selector-body .ant-input-affix-wrapper input {
  background-color: inherit;
}
.cohort-selector .cohort-selector-body .ant-input-affix-wrapper input::placeholder {
  color: rgba(0, 0, 0, 0.45);
}
.cohort-selector .cohort-selector-body .ant-input-affix-wrapper:focus,
.cohort-selector .cohort-selector-body .ant-input-affix-wrapper:active,
.cohort-selector .cohort-selector-body .ant-input-affix-wrapper:hover {
  border-color: #d9d9d9;
  box-shadow: none;
}
.cohort-selector .cohort-selector-body .ant-row {
  box-shadow: 0px 3px 8px #d9d9d9;
}
