.ImageCheckbox {
  text-align: center;
  width: 170px;
  height: 170px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 0px 7px 0px #dad5d5;
  border-radius: 5px;
  padding: 5px;
}
.ImageCheckbox.checked {
  border: 2px solid #147F74;
  box-shadow: 0px 0px 7px 0px #147F74;
}
.ImageCheckbox .title {
  font-weight: bold;
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.ImageCheckbox .ant-checkbox {
  position: absolute;
  right: 5px;
  top: 5px;
}
