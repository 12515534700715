div.ant-card-body {
  padding: 0px;
}
.initiative-card {
  border-radius: 5px;
  height: 100%;
  background: #fdfdfd;
  border: none;
  box-shadow: 1px 0 0 0 #7b89891a, 0 1px 0 0 #7b89891a, 0 -1px 0 0 #7b89891a, -1px 0 0 0 #7b89891a, 0 2px 4px 0 #7b89891a;
}
.initiative-card .card-image {
  padding-top: 60%;
  position: relative;
}
.initiative-card .card-image .programWrapper {
  position: absolute;
  z-index: 1;
  top: 5px;
  left: 5px;
  height: 30px;
  background-color: #FFF;
  border-radius: 6px;
  color: #147F74;
  padding: 0;
  display: flex;
  align-items: stretch;
  flex-direction: column;
}
.initiative-card .card-image .programWrapper > span {
  background-color: rgba(20, 127, 116, 0.15);
  padding: 3px 10px;
  font-size: 1.2em;
  font-weight: bold;
}
.initiative-card .card-image .pinWrapper {
  position: absolute;
  z-index: 1;
  top: 5px;
  right: 5px;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 6px;
  color: #FFF;
  padding: 4px 5px 0px;
}
.initiative-card .card-image .pinWrapper > span {
  color: white;
  font-size: 1.5em;
}
.initiative-card .card-image > img {
  border-radius: 5px 5px 0px 0px;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
}
.initiative-card .card-body {
  width: 100%;
  height: 100%;
  padding: 12px 12px 50px 12px;
}
.initiative-card .card-body p {
  margin-bottom: 5px;
}
.initiative-card .card-body .card-title {
  line-height: 1.2;
  font-weight: 600;
  font-size: 19px;
  color: #5f5f5f;
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  margin-top: 5px;
}
.initiative-card .card-body .card-address {
  color: #7b8989;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.initiative-card .initiative-type {
  position: absolute;
  left: 12px;
  bottom: 5px;
  display: flex;
  align-items: center;
}
.initiative-card .initiative-type svg {
  width: 15px;
  height: 15px;
  margin: 0px 5px 5px 0px;
}
.initiative-card .contribution {
  color: #7b8989;
  line-height: 1;
}
.initiative-card .sdgs {
  position: absolute;
  bottom: 5px;
  right: 10px;
  display: flex;
}
.initiative-card .sdgs .InitiativeCard-sdgs-popover {
  margin-top: 8px;
  cursor: pointer;
}
.date {
  flex-direction: column;
  align-items: center;
  width: 100%;
  font-size: 19px;
}
.date .day {
  position: absolute;
}
.date .month {
  position: absolute;
  font-size: 11px;
}
.card-date {
  position: absolute;
  bottom: -10px;
  height: 38px;
  width: 80px;
  margin-left: 10px;
  background: white;
  border-radius: 3px;
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.15);
  display: flex;
  align-items: center;
  z-index: 1;
}
.card-date .start-date {
  flex-direction: column;
  align-items: center;
  width: 100%;
  font-size: 19px;
  color: #147F74;
}
.card-date .start-date .day {
  position: absolute;
}
.card-date .start-date .month {
  position: absolute;
  font-size: 11px;
}
.card-date .start-date .day {
  left: 10px;
  top: 0px;
}
.card-date .start-date .month {
  left: 7px;
  bottom: 1px;
}
.card-date .end-date {
  flex-direction: column;
  align-items: center;
  width: 100%;
  font-size: 19px;
}
.card-date .end-date .day {
  position: absolute;
}
.card-date .end-date .month {
  position: absolute;
  font-size: 11px;
}
.card-date .end-date .day {
  right: 10px;
  top: 0px;
}
.card-date .end-date .month {
  right: 8px;
  bottom: 1px;
}
.card-date.single {
  width: 40px;
}
.card-date.canceled {
  width: auto;
  padding: 0px 10px;
  color: #f30303;
}
.card-date .divider {
  width: 18px;
  height: 2px;
  background: rgba(123, 137, 137, 0.3);
  margin: 18px 0;
}
.card-button {
  position: absolute;
  right: 10px;
  bottom: 10px;
  font-weight: 500;
  font-size: 0.7em;
  color: #FFF;
  background-color: #147F74;
  border-color: #147F74;
}
.card-button:hover,
.card-button:focus,
.card-button:active {
  color: #FFF;
  background-color: #147F74;
  border-color: #147F74;
}
.card-points {
  position: absolute;
  top: 10px;
  right: 5px;
  font-weight: 500;
  font-size: 0.7em;
  color: #FFF;
  background-color: rgba(236, 110, 37, 0.9);
  height: 20px;
  padding: 0 10px 0 10px;
  border-radius: 6px;
  line-height: 20px;
}
.card-points.isPinned {
  top: 45px;
}
.not-going-modal .ant-modal-content {
  border-radius: 6px;
}
.not-going-modal .ant-modal-title {
  font-size: 1.4rem;
}
.not-going-modal .ant-modal-header {
  border: none;
  border-radius: 6px 6px 0px 0px;
}
.not-going-modal .ant-modal-body {
  border: none;
  font-size: 1rem;
}
.not-going-modal .ant-modal-footer {
  border: none;
}
.not-going-modal .ant-modal-footer div .ant-btn {
  height: 45px;
  border-radius: 6px;
  min-width: 120px;
  font-size: 1rem;
  border-color: #147F74;
  border-width: 2px;
}
.not-going-modal .ant-modal-footer div .ant-btn:hover,
.not-going-modal .ant-modal-footer div .ant-btn:focus,
.not-going-modal .ant-modal-footer div .ant-btn:active {
  color: #FFF;
  background-color: #147F74;
  border-color: #147F74;
}
.not-going-modal .ant-modal-footer div .ant-btn-primary {
  color: #FFF;
  background-color: #147F74;
  border-color: #147F74;
}
.InitiativeCard-sdgs-popover-content > .sdgLogo {
  display: inline-block;
}
