.ContributionScreen .affix-button button {
  height: 50px;font-size: 1rem;font-weight: 600;border-radius: 6px;color: #FFF;background-color: #147F74;border-color: #147F74;
}
.ContributionScreen .affix-button button:hover,
.ContributionScreen .affix-button button:active,
.ContributionScreen .affix-button button:focus {
  color: #FFF;background-color: #147F74;border-color: #147F74;
}
.ContributionScreen .affix-button button:disabled {
  color: rgba(0, 0, 0, 0.25);background-color: #f5f5f5;border-color: rgba(0, 0, 0, 0.25);
}
.ContributionScreen .ant-drawer-body {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.ContributionScreen .ant-drawer-header {
  border: none;
}
.ContributionScreen .ant-drawer-header .ant-drawer-title {
  font-size: 1.5em;
}
.ContributionScreen .affix-button {
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
}
.ContributionScreen .affix-button button {
  width: 90%;
  margin: 5%;
}
.ContributionScreen__big-title {
  font-size: 32px;
  text-align: center;
  font-weight: bold;
}
.ContributionScreen__desc {
  margin-bottom: 20px;
  text-align: center;
}
.ContributionScreen__radiocontainer-h {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
}
.ContributionScreen__source {
  font-size: 1.2em;
}
.ContributionScreen__radio {
  flex: 0 1;
  /*
    input[type="radio"]:focus + label {
      color: @default-button-hover-color;
      background-color: @default-button-hover-bg-color;
      border-color: @default-button-hover-border-color;
    }
    */
}
.ContributionScreen__radio input[type="radio"] {
  opacity: 0;
  position: fixed;
  width: 0;
}
.ContributionScreen__radio label {
  display: inline-block;
  background-color: #FFF;
  padding: 10px 20px;
  font-size: 16px;
  border: 2px solid #147F74;
  color: #117065;
  border-radius: 10px;
  text-align: center;
  margin-right: 2px;
}
.ContributionScreen__radio input[type="radio"]:checked + label {
  color: #FFF;
  background-color: #062724;
  border-color: #062724;
}
.ContributionScreen__accordion-panel-disabled {
  color: #ddd;
}
.ContributionScreen__disable-selection {
  text-align: center;
  font-size: 20px;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                  supported by Chrome, Opera and Firefox */
}
.ContributionScreen__desc {
  color: #444;
}
.ContributionScreen__cash .ant-row h1 {
  font-size: 4em;
  margin: 0;
}
.ContributionScreen__cash .ant-row h3 {
  font-size: 2em;
  font-weight: 200;
  margin-bottom: 5vh;
  margin-top: -10px;
}
.ContributionScreen__cash .ant-row h4 {
  font-size: 1.3em;
  color: #7d7d7d;
  margin-bottom: -5px;
}
.ContributionScreen__instructions {
  white-space: pre-wrap;
  color: #333;
}
.ContributionScreen__input .ant-input-number {
  border: none;
  width: 100%;
  box-shadow: none;
  outline: none;
}
.ContributionScreen__input .ant-input-number-handler-wrap {
  display: none;
}
.ContributionScreen__input input {
  font-size: inherit;
  height: 90px;
  text-align: center;
  border: none;
  border-bottom: 2px solid #c0c0c0;
  margin-bottom: 15px;
  padding-right: 50px;
}
.ContributionScreen__input input:focus,
.ContributionScreen__input input:hover {
  box-shadow: none;
  border-color: #c0c0c0;
}
.ContributionScreen__input span {
  font-size: 1.5em;
  position: absolute;
  right: 16px;
  font-weight: bold;
}
.ContributionScreen__icon {
  margin-right: 10px;
  font-size: 20px;
}
.ContributionScreen__list .ant-list-item span {
  /*
    Hack to keep it in the DOM but change its color so it's not visible
    We need it in the DOM so it can be copied.
   */
  color: #fff;
}
.ContributionScreen__checkbox p {
  margin-left: 32px;
}
.ContributionScreen__checkbox .ant-checkbox-input:hover,
.ContributionScreen__checkbox .ant-checkbox-input:focus,
.ContributionScreen__checkbox .ant-checkbox-input:active {
  height: initial;
}
.ContributionScreen__message {
  margin: 15px 0 15px 0;
  border: 2px solid #f5f5f5;
}
.ContributionScreen__message:hover,
.ContributionScreen__message:focus {
  border-color: #f5f5f5;
  box-shadow: none;
}
