.AboutOurProgram-content {
  margin-top: 15px;
}
.AboutOurProgram-header {
  background: #FFF;
  padding-bottom: 5px;
  height: auto;
}
.AboutOurProgram-header h1 {
  font-size: 2rem;
  color: #000;
  font-weight: bold;
  margin: 0 1rem;
}
@media only screen and (min-width:992px) {
  .AboutOurProgram-header h1 {
    font-size: 50px;
  }
}
