.Onboarding__buttonrow-wrapper {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 998;
}
.Onboarding__buttonrow {
  position: absolute;
  width: 100%;
  max-width: 1024px;
  bottom: 0;
  background: #ddd;
  display: flex;
}
.Onboarding__innerbutton {
  flex: 1;
  margin: 5px 10px;
}
.Onboarding__buttonrow-placeholder {
  height: 65px;
  display: block;
  width: 100%;
  background: transparent;
}
