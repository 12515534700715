.LoadingSignedIn-back {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #147F74;
  display: flex;
  justify-content: center;
  align-items: center;
}
.LoadingSignedIn-back img {
  max-width: 128px;
  max-height: 128px;
  filter: brightness(0) invert(1);
}
