.RatingScreen .affix-button button {
  height: 50px;font-size: 1rem;font-weight: 600;border-radius: 6px;color: #FFF;background-color: #147F74;border-color: #147F74;
}
.RatingScreen .affix-button button:hover,
.RatingScreen .affix-button button:active,
.RatingScreen .affix-button button:focus {
  color: #FFF;background-color: #147F74;border-color: #147F74;
}
.RatingScreen .affix-button button:disabled {
  color: rgba(0, 0, 0, 0.25);background-color: #f5f5f5;border-color: rgba(0, 0, 0, 0.25);
}
.RatingScreen .ant-drawer-header {
  border-bottom: none;
}
.RatingScreen .ant-drawer-header .ant-drawer-title {
  font-size: 1.5rem;
  font-weight: bold;
}
.RatingScreen-row {
  color: #6f6f6f;
  font-size: 1rem;
}
.RatingScreen .rc-rate {
  font-size: 50px;
}
.RatingScreen .affix-button {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}
.RatingScreen .affix-button span {
  margin-left: 1rem;
}
.RatingScreen .affix-button button {
  width: 90%;
  margin: 5%;
}
