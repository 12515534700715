.ConfirmEmail-corporatelogo {
  max-width: 70%;
  max-height: 20vh;
  margin-top: 15px;
  margin-bottom: 15px;
  display: flex;
}
.ConfirmEmail-corporatelogo img {
  object-fit: contain;
  display: block;
}
.ConfirmEmail label {
  margin-left: 20px;
}
.ConfirmEmail input {
  width: 100%;
  height: 40px;
}
.ConfirmEmail button.cta {
  width: 90%;
  margin: 20px;
}
@media only screen and (min-width:576px) {
  .ConfirmEmail button.cta {
    width: 60%;
    margin: 5% 20%;
  }
}
.ConfirmEmail .ant-col {
  position: static;
}
@media only screen and (min-width:576px) {
  .ConfirmEmail .ant-col {
    position: relative;
  }
}
.ConfirmEmail .cover {
  min-height: 100vh;
}
