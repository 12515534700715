.initiatives {
  width: 1px;
  margin: 0px 16px 40px 16px;
}
.initiatives .ant-tabs-tabpane {
  padding: 2px;
}
.initiatives-filters {
  margin-top: 10px;
}
.initiatives-filters .InitiativeFilter-search input.ant-input {
  height: 100%;
}
@media only screen and (min-width:992px) {
  .initiative-tab {
    display: flex;
    align-items: center;
  }
}
.initiative-tab h1 {
  font-weight: 600;
  color: #273941;
  font-size: 2rem;
  margin: 0px 0px 10px 0px;
}
@media only screen and (min-width:992px) {
  .initiative-tab h1 {
    font-size: 50px;
  }
}
.initiative-tab .tabs {
  font-size: 1rem;
  margin-bottom: 20px;
  font-weight: 600;
  cursor: pointer;
}
@media only screen and (min-width:992px) {
  .initiative-tab .tabs {
    font-size: 29px;
    border-left: 1px solid #d9d9d9;
    margin: 0px 0px 5px 30px;
    padding: 4px 40px 0px 30px;
  }
}
.initiative-tab .tabs span {
  padding: 0px 5px 5px 5px ;
  margin-right: 10px;
}
@media only screen and (min-width:992px) {
  .initiative-tab .tabs span {
    margin-right: 25px;
  }
}
.initiative-tab .tabs .active {
  color: #273941;
  border-bottom: 2px solid #147F74;
}
.initiative-tab .tabs .inactive {
  color: #7B8989;
}
.initiatives__success_message a {
  text-decoration: underline;
}
.initiatives__success_message a:link {
  color: #0000EE;
}
.initiatives__success_message a:visited {
  color: #551A8B;
}
.no-initiative {
  padding: 40px 10px 10px 10px;
  display: flex;
  flex-direction: column;
  text-align: -webkit-center;
}
.no-initiative .empty {
  margin: 10px;
}
.no-initiative .empty .ant-empty-image {
  height: 150px;
}
.no-initiative .empty .ant-empty-description {
  margin-top: 60px;
  font-size: 2rem;
  font-weight: 600;
  color: #273941;
}
.no-initiative h2 {
  color: #7B8989;
}
.no-initiative .button {
  margin: 40px 0px 20px 0px;
  color: #FFF;
  background-color: #147F74;
  border-color: #147F74;
  height: 50px;
  font-size: 1rem;
  font-weight: 600;
  width: 100%;
  align-self: center;
}
@media only screen and (min-width:992px) {
  .no-initiative .button {
    height: 46px;
    width: fit-content;
  }
}
.initiativeBanner {
  width: 100%;
  margin-bottom: 5px;
}
.initiativeCard-wrapper {
  width: 100%;
  height: 100%;
}
