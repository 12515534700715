.QuoteWall {
  height: 100%;
  display: flex;
  flex: 50%;
  flex-direction: column;
  justify-content: flex-end;
}
.QuoteWall .container {
  text-shadow: 0 1px 0 black;
  color: white;
  padding: 6em 4em 6em 12vw;
}
.QuoteWall .container .intro {
  color: grey;
  font-size: 35px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  text-align: right;
}
.QuoteWall .container .quote {
  font-size: 65px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: -1.75px;
  text-align: right;
  display: block;
  margin-bottom: 15px;
}
.QuoteWall .container .author {
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: italic;
  text-align: right;
}
