.Loading-back {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.Loading-back img {
  max-width: 128px;
  max-height: 128px;
}
