.OrganizationLogo {
  background: transparent;
  width: 100%;
}
.OrganizationLogo img {
  object-fit: contain;
  display: block;
  max-width: 100%;
  max-height: 100%;
}
