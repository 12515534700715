.DatePicker {
  display: inline-block;
}
.ant-picker {
  height: 40px;
  border-radius: 100px;
  border: 2px solid #d9d9d9;
  box-shadow: none;
  margin-right: 5px;
}
.ant-picker:hover,
.ant-picker:focus,
.ant-picker:active {
  border-color: #147F74;
  box-shadow: none;
  border-right-width: 2px !important;
}
.ant-picker-range .ant-picker-active-bar {
  background: #147F74;
}
.ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
  background: #147F74;
}
.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
  border-color: #147F74;
}
.ant-picker-footer-extra {
  text-align: right;
}
.ant-picker-footer-extra button {
  color: #147F74;
  border-color: #147F74;
  border-radius: 5px;
}
.ant-picker-footer-extra button:hover,
.ant-picker-footer-extra button:active,
.ant-picker-footer-extra button:focus {
  color: #FFF;
  background-color: #147F74;
}
.am-calendar .confirm-panel .button,
.am-calendar .single-month .row .cell .date-wrapper .date-selected {
  background: #147F74;
}
