.ant-layout {
  background-color: #FFF;
}
.ProposalInitiative-description,
.ProposalInitiative-title {
  align-self: stretch;
  display: flex;
  flex: 1 1;
  overflow: hidden;
  position: relative;
}
.ProposalInitiative-when,
.ProposalInitiative-where,
.ProposalInitiative-sdgs,
.ProposalInitiative-competences,
.ProposalInitiative-send,
.ProposalInitiative-description {
  margin: 1rem;
}
.ProposalInitiative-when .ProposalInitiative-heading,
.ProposalInitiative-where .ProposalInitiative-heading,
.ProposalInitiative-sdgs .ProposalInitiative-heading,
.ProposalInitiative-competences .ProposalInitiative-heading,
.ProposalInitiative-send .ProposalInitiative-heading,
.ProposalInitiative-description .ProposalInitiative-heading {
  color: #414141;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 0.7rem;
  margin-top: 1rem;
}
.ProposalInitiative-when .ProposalInitiative-subheading,
.ProposalInitiative-where .ProposalInitiative-subheading,
.ProposalInitiative-sdgs .ProposalInitiative-subheading,
.ProposalInitiative-competences .ProposalInitiative-subheading,
.ProposalInitiative-send .ProposalInitiative-subheading,
.ProposalInitiative-description .ProposalInitiative-subheading {
  color: #9b9b9b;
  font-weight: bold;
}
.ProposalInitiative-title .ProposalInitiative-input input,
.ProposalInitiative-where .ProposalInitiative-input input {
  background-color: transparent;
  box-sizing: border-box;
  width: 100%;
}
.ProposalInitiative-send input,
.ProposalInitiative-where input {
  border: 2px solid #d9d9d9;
  border-radius: 5px;
}
.ProposalInitiative-button-next {
  margin: 0 1rem;
  position: absolute;
  bottom: 70px;
  text-align: center;
  width: -webkit-fill-available;
}
.ProposalInitiative-button-next .ant-btn {
  background-color: #147F74;
  border: 2px solid #147F74;
  border-radius: 5px;
  color: #FFF;
  font-weight: bold;
  font-size: 16px;
  height: 47px;
  width: 100%;
}
.ProposalInitiative-button-next .ant-btn:hover,
.ProposalInitiative-button-next .ant-btn:focus,
.ProposalInitiative-button-next .ant-btn:active {
  text-decoration: none;
  outline: 0;
}
@media only screen and (min-width:992px) {
  .ProposalInitiative-button-next .ant-btn {
    width: 40%;
    margin-top: 5rem;
  }
}
@media only screen and (min-width:1200px) {
  .ProposalInitiative-button-next .ant-btn {
    width: 20%;
  }
}
.ProposalInitiative-button-next .ProposalInitiative-button-skip {
  margin: 1rem 0;
}
.ProposalInitiative-button-next .ProposalInitiative-button-skip span {
  font-size: 18px;
  color: #a9a9a9;
  cursor: pointer;
}
@media only screen and (min-width:992px) {
  .ProposalInitiative-button-next {
    position: relative;
  }
}
.ProposalInitiative-title {
  margin: 40% 1rem;
}
.ProposalInitiative-title .ProposalInitiative-input {
  border-bottom: 2px solid #ddd;
}
.ProposalInitiative-title .ProposalInitiative-input input {
  border: 0;
  color: #000;
  font-size: 3rem;
  line-height: 1;
  padding: 2px 0;
  -webkit-appearance: none;
}
.ProposalInitiative-title .ProposalInitiative-input .ant-input-affix-wrapper {
  border: none;
}
.ProposalInitiative-title .ProposalInitiative-input .ant-input-affix-wrapper svg {
  color: #f76a24;
}
.ProposalInitiative-title .ProposalInitiative-limit {
  color: #a9a9a9;
  text-align: end;
}
@media only screen and (min-width:992px) {
  .ProposalInitiative-title {
    margin: 30% 1rem;
  }
}
@media only screen and (min-width:1200px) {
  .ProposalInitiative-title {
    margin: 10% 1rem;
  }
}
.ProposalInitiative-description .ProposalInitiative-textarea textarea {
  border: 2px solid #d9d9d9;
  border-radius: 5px;
}
@media only screen and (min-width:992px) {
  .ProposalInitiative-description {
    margin: 1rem;
  }
}
.ProposalInitiative-when .ProposalInitiative-date {
  margin-right: 1rem;
}
.ProposalInitiative-when .ant-picker {
  height: 47px;
  border: 2px solid #d9d9d9;
  border-radius: 5px;
  width: 100%;
}
.ProposalInitiative-where .ProposalInitiative-input input {
  color: #000;
  font-size: 18px;
  margin-bottom: 1rem;
  padding: 0.7rem;
}
.ProposalInitiative-where .ProposalInitiative-input input:hover,
.ProposalInitiative-where .ProposalInitiative-input input:focus {
  border-color: #d9d9d9;
  box-shadow: 0 0 0 1px #d9d9d9;
}
.ProposalInitiative-where .ant-list {
  background-color: #FFF;
  z-index: 999;
}
.ProposalInitiative-where .ProposalInitiative-map {
  height: 300px;
  overflow: hidden;
}
.ProposalInitiative-where .ProposalInitiative-map > div {
  height: 100%;
  padding-top: 0;
}
.ProposalInitiative-sdgs .ProposeInitiative-sdglogo-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, 100px);
  grid-gap: 13px 13px;
}
.ProposalInitiative-sdgs .ProposeInitiative-sdglogo-container .ProposeInitiative-sdglogo {
  width: 100px;
}
.ProposalInitiative-sdgs .ProposeInitiative-sdglogo-container .ProposeInitiative-sdglogo img {
  width: 100px;
}
.ProposalInitiative-where input,
.ProposalInitiative-send input {
  height: 40px;
}
.ProposalInitiative-where input:hover,
.ProposalInitiative-send input:hover,
.ProposalInitiative-where input:focus,
.ProposalInitiative-send input:focus,
.ProposalInitiative-where input:active,
.ProposalInitiative-send input:active {
  border-color: #d9d9d9;
}
.ProposalInitiative-where .ant-radio-button-wrapper,
.ProposalInitiative-send .ant-radio-button-wrapper {
  border: 2px solid #d9d9d9;
  color: #a9a9a9;
  margin-bottom: 2rem;
}
.ProposalInitiative-where .ant-radio-button-wrapper:first-child,
.ProposalInitiative-send .ant-radio-button-wrapper:first-child {
  border-right: none;
  border-radius: 5px 0 0 5px;
}
.ProposalInitiative-where .ant-radio-button-wrapper:last-child,
.ProposalInitiative-send .ant-radio-button-wrapper:last-child {
  border-left: none;
  border-radius: 0 5px 5px 0;
}
.ProposalInitiative-where .ant-radio-button-wrapper-checked.ant-radio-button-wrapper,
.ProposalInitiative-send .ant-radio-button-wrapper-checked.ant-radio-button-wrapper {
  background-color: #147F74;
  border-color: #147F74;
  color: #FFF;
  z-index: 1;
}
.ProposalInitiative-where .ant-radio-button-wrapper-checked.ant-radio-button-wrapper:hover::before,
.ProposalInitiative-send .ant-radio-button-wrapper-checked.ant-radio-button-wrapper:hover::before,
.ProposalInitiative-where .ant-radio-button-wrapper-checked.ant-radio-button-wrapper:hover,
.ProposalInitiative-send .ant-radio-button-wrapper-checked.ant-radio-button-wrapper:hover {
  color: #FFF;
  border-color: #147F74;
}
.ProposalInitiative-where .ant-radio-button-wrapper-checked.ant-radio-button-wrapper::before,
.ProposalInitiative-send .ant-radio-button-wrapper-checked.ant-radio-button-wrapper::before {
  background-color: #147F74;
}
.ant-modal-body .ant-btn {
  border-radius: 5px;
  font-weight: bold;
  color: #147F74;
  border: 2px solid #117065;
}
.ant-modal-body .ant-btn:hover {
  text-decoration: none;
  outline: 0;
}
.ant-modal-body .ant-btn:focus {
  text-decoration: none;
  outline: 0;
}
.ant-modal-body .ant-btn:active {
  text-decoration: none;
  outline: 0;
}
.ant-modal-body .ant-btn-primary {
  background-color: #147F74;
  color: #FFF;
}
.ProposalInitiative-finish .ProposalInitiative-message {
  margin: 30% 1rem;
}
.ProposalInitiative-finish .ProposalInitiative-message .ProposalInitiative-message-heading {
  font-weight: bold;
  font-size: 18px;
}
@media only screen and (min-width:992px) {
  .ProposalInitiative-finish .ProposalInitiative-message .ProposalInitiative-message-heading {
    font-size: 2rem;
  }
}
.ProposalInitiative-finish .ProposalInitiative-message .ProposalInitiative-message-description {
  color: #717171;
}
@media only screen and (min-width:992px) {
  .ProposalInitiative-finish .ProposalInitiative-message .ProposalInitiative-message-description {
    font-size: 1rem;
  }
}
.ProposalInitiative-finish .ProposalInitiative-message .ant-col {
  text-align: center;
}
@media only screen and (min-width:1200px) {
  .ProposalInitiative-finish .ProposalInitiative-message {
    margin: 10% 1rem;
  }
}
.ProposalInitiative-finish .ProposalInitiative-button-next {
  margin: 0 1rem;
}
.ProposalInitiative-types {
  padding: 10px;
}
.ProposalInitiative-types-card {
  border: 2px solid #EEE;
  border-radius: 5px;
  height: 200px;
  cursor: pointer;
}
.ProposalInitiative-types-card .name {
  width: 100%;
  text-align: center;
  font-size: 1.5em;
  font-weight: bold;
}
.ProposalInitiative-types-card:hover {
  border-color: #147F74;
}
.ProposalInitiative-types-card.selected {
  border-color: #147F74;
}
.relative {
  position: relative;
  bottom: 0;
}
