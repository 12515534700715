.progressbar {
  width: 100%;
}
.progressbar .ant-progress-bg {
  background-color: #147F74;
}
.progressbar p {
  font-size: 15px;
  color: #273941;
}
