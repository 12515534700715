.AvatarEditor .ant-modal-body {
  text-align: center;
}
.AvatarEditor .AvatarEditor-slider {
  touch-action: manipulation;
}
.AvatarEditor .ant-modal-footer {
  display: none;
}
.AvatarEditor button {
  width: 100%;
}
.AvatarEditor .AvatarEditor-slider {
  margin: 2rem 0;
}
canvas {
  max-width: 100%;
  height: auto !important;
}
