.ResponsibleProfile-header,
.ResponsibleProfile-details {
  background: #FFF;
  height: auto;
}
.ResponsibleProfile-header > div {
  margin: 0 1rem;
}
.ResponsibleProfile-header a {
  color: #147F74;
}
.ResponsibleProfile-header a > h1 {
  font-size: 2rem;
  margin: 0;
  color: #000;
}
.ResponsibleProfile-header a.active > h1 {
  font-size: 2rem;
  font-weight: bold;
}
@media only screen and (min-width:992px) {
  .ResponsibleProfile-header a.active > h1 {
    font-size: 50px;
  }
}
.ResponsibleProfile-header .ResponsibleProfile-settings-icon {
  text-align: end;
}
.ResponsibleProfile-header .ResponsibleProfile-settings-icon svg {
  height: 2rem;
  width: 2rem;
  max-width: 2rem;
  max-height: 2rem;
}
.ResponsibleProfile-details .ResponsibleProfile-avatarwrapper {
  position: relative;
  height: 150px;
  width: 150px;
  max-width: 150px;
  max-height: 150px;
  margin: auto;
}
.ResponsibleProfile-details .ResponsibleProfile-avatarwrapper svg {
  color: #147F74;
}
.ResponsibleProfile-details .ResponsibleProfile-avatarwrapper .Avatar-initials {
  font-size: 2em;
}
.ResponsibleProfile-details .Profile-fileupload {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
  opacity: 0;
  z-index: 9;
}
.ResponsibleProfile-details .Profile-plussign {
  position: absolute;
  bottom: 0;
  right: -12px;
  background: #CCC;
  border-radius: 50%;
}
.ResponsibleProfile-headertext {
  color: #147F74;
  font-weight: bold;
  margin-top: 1rem;
  text-align: center;
}
.ResponsibleProfile-headertext .ResponsibleProfile-icon {
  margin-right: 10px;
  font-size: 20px;
}
.ResponsibleProfile-headertext .ResponsibleProfile-name {
  color: #5a5c5e;
  font-size: 1.7rem;
  font-weight: bold;
  margin-bottom: 10px;
}
.ResponsibleProfile-headertext .ResponsibleProfile-regulartext {
  color: #a9a9a9;
  margin-bottom: 1rem;
}
.ResponsibleProfile-stats {
  padding: 0 1rem;
  margin-bottom: 1rem;
}
.ResponsibleProfile-stats .ResponsibleProfile-statcard {
  height: 100%;
}
.ResponsibleProfile-stats .ResponsibleProfile-statcard .ResponsibleProfile-emptytext {
  margin-bottom: 10px;
}
.ResponsibleProfile-stats .ResponsibleProfile-statcard .ResponsibleProfile-emptytext .ant-empty-image {
  height: 40px;
}
.ResponsibleProfile-stats .ResponsibleProfile-statcard .ResponsibleProfile-card-row {
  padding: 0 15px;
  margin: 0 !important;
  height: 4rem;
  color: #2f2f2f;
}
.ResponsibleProfile-stats .ResponsibleProfile-statcard .ResponsibleProfile-card-row .ResponsibleProfile-card-thumb div {
  max-height: 50px;
  max-width: 50px;
  margin: auto;
}
.ResponsibleProfile-stats .ResponsibleProfile-statcard .ResponsibleProfile-card-row .ResponsibleProfile-card-thumb div img {
  width: 100%;
  height: 100%;
}
@media only screen and (min-width:992px) {
  .ResponsibleProfile-stats .ResponsibleProfile-statcard .ResponsibleProfile-card-row .ResponsibleProfile-card-thumb div {
    width: 50px;
    height: 50px;
  }
}
@media only screen and (min-width:992px) {
  .ResponsibleProfile-stats .ResponsibleProfile-statcard .ResponsibleProfile-card-row .ResponsibleProfile-card-thumb {
    flex: 0;
  }
}
.ResponsibleProfile-stats .ResponsibleProfile-statcard .ResponsibleProfile-card-row .ResponsibleProfile-card-name {
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.ResponsibleProfile-stats .ResponsibleProfile-statcard .ResponsibleProfile-card-row .ResponsibleProfile-card-name > div {
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.ResponsibleProfile-stats .ResponsibleProfile-statcard .ResponsibleProfile-card-row .ResponsibleProfile-card-hours {
  font-weight: bold;
  text-align: end;
}
.ResponsibleProfile-stats .ResponsibleProfile-statcard .ResponsibleProfile-card-row .ResponsibleProfile-date {
  font-size: 14px;
  color: #888;
}
.ResponsibleProfile-stats .ResponsibleProfile-statcard .ResponsibleProfile-card-row:nth-child(even) {
  background-color: #F6F7F7;
}
.ResponsibleProfile-stats .ResponsibleProfile-statcard .ResponsibleProfile-card-row:nth-child(odd) {
  background-color: #ffffff;
}
.ResponsibleProfile-stats .ResponsibleProfile-statcard .card-header-content {
  width: 80%;
  color: #9e9e9e;
  padding: 9px 15px;
  font-size: 17px;
  font-weight: bold;
  display: flex;
  text-align: left;
}
.ResponsibleProfile-stats .ResponsibleProfile-statcard .ResponsibleProfile-largetext {
  font-size: 3em;
  font-weight: bold;
  margin-left: 1rem;
}
.ResponsibleProfile-pdfbutton {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #147F74;
  font-weight: bold;
  font-size: 16px;
  color: #147F74;
  padding: 0 15px;
  width: 100%;
  height: 47px;
  line-height: 47px;
  border-radius: 5px;
}
.ResponsibleProfile-pdfbutton svg {
  margin-right: 1rem;
  color: #147F74;
}
@media only screen and (min-width:992px) {
  .ResponsibleProfile-pdfbutton {
    width: 40%;
    margin: auto;
  }
}
@media only screen and (min-width:1200px) {
  .ResponsibleProfile-pdfbutton {
    width: 20%;
  }
}
.ResponsibleProfile-pdfbutton:hover {
  color: #147F74;
  border-color: #147F74;
}
.ResponsibleProfile-pdfbutton:focus {
  color: #147F74;
  border-color: #147F74;
}
.ResponsibleProfile-pdfbutton:active {
  color: #147F74;
  border-color: #147F74;
}
.Settings-contact svg {
  color: #147F74;
}
