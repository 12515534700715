.phone-drawer > .ant-drawer-content-wrapper {
  display: flex;
  align-items: flex-end;
}
.phone-drawer > .ant-drawer-content-wrapper .affix-button {
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
}
.phone-drawer > .ant-drawer-content-wrapper .affix-button button {
  width: 90%;
  margin: 5%;
}
.phone-drawer > .ant-drawer-content-wrapper .ant-drawer-content {
  width: 100vw;
}
.phone-drawer > .ant-drawer-content-wrapper .ant-drawer-content .ant-drawer-header {
  border: 0px;
}
.phone-drawer > .ant-drawer-content-wrapper .ant-drawer-content .ant-drawer-header .ant-drawer-title {
  font-size: 18px;
  font-weight: 600;
}
.phone-drawer h3 {
  text-align: center;
  font-weight: 600;
}
.phone-drawer .ant-alert {
  margin-bottom: 5px;
}
.phone-drawer .check-icon-wrapper {
  text-align: center;
}
.phone-drawer .check-icon-wrapper .check-icon {
  margin: 0px 0px 30px 0px;
  font-size: 140px;
  color: #d4f2e4;
}
.phone-drawer p {
  text-align: center;
}
.phone-drawer .finish-button {
  margin-top: 30px;
  width: 100%;
  border-radius: 5px;
}
.phone-drawer .finish-button:enabled {
  color: white;
  background-color: #147F74;
  border-color: #147F74;
}
