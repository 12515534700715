.Rankings-header {
  background: #FFF;
  padding-bottom: 5px;
  height: auto;
}
.Rankings-header h1 {
  font-size: 2rem;
  color: #000;
  font-weight: bold;
  margin: 0 1rem;
}
@media only screen and (min-width:992px) {
  .Rankings-header h1 {
    font-size: 50px;
  }
}
.Rankings-headertext {
  color: #FFF;
  font-weight: bold;
  width: 100%;
}
.Rankings-headertext h2 {
  color: #000;
  font-size: 22px;
  font-weight: bold;
  margin-left: 1rem;
}
.Rankings-emptytext {
  text-align: center;
}
.Rankings-avatarwrapper {
  position: relative;
}
.Rankings-headerlogo {
  width: auto;
  margin-left: 5px;
  height: 64px;
  max-width: 50%;
  overflow: hidden;
}
.Rankings-grayscale-headerlogo {
  filter: grayscale(100%) brightness(200%);
}
.Rankings-white-headerlogo {
  filter: brightness(0) invert(1);
}
