.embedded .NavTopBack {
  display: none;
}
.NavTopBack {
  color: #fff;
  top: 10px;
  position: fixed;
  left: 8px;
  z-index: 10;
  background: #147F74;
  border-radius: 24px;
  padding: 9px 15px;
}
@media only screen and (min-width:992px) {
  .NavTopBack {
    margin-left: 5px;
  }
}
.NavTopBack:hover,
.NavTopBack:active {
  color: #333;
}
.NavTopBack .back-text {
  display: none;
}
.NavTopBack svg {
  font-size: 1.5rem;
  position: relative;
  top: 2px;
}
@media only screen and (min-width:992px) {
  .NavTopBack svg {
    margin-left: 0px;
  }
}
@media only screen and (min-width:992px) {
  .NavTopBack {
    position: initial;
    padding-left: 0;
    /* These aren't themed so, important is required. */
    background: none !important;
    color: #677379 !important;
  }
  .NavTopBack .back-text {
    color: #677379;
    font-size: 1.2rem;
    display: inline-block;
    margin-left: 5px;
  }
  .NavTopBack .back-text:hover,
  .NavTopBack .back-text:active {
    color: #333;
  }
}
.App.ios .NavTopBack {
  top: 70px;
}
