.FileItem__item {
  display: flex;
  flex-direction: row;
  margin-bottom: 12px;
}
.FileItem__icon {
  width: 36px;
  height: 46px;
  margin-right: 8px;
}
.FileItem__detail {
  display: flex;
  flex-direction: column;
}
.FileItem__name {
  font-size: 15px;
  font-weight: 500;
  color: #273941;
}
.FileItem__info {
  font-size: 13px;
  font-weight: normal;
  color: rgba(39, 57, 65, 0.5);
}
