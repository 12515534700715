.A-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  text-decoration: none;
  color: #007AFF;
  display: inline;
  margin: 0;
  padding: 0;
}
.A-button:hover,
.A-button:focus {
  text-decoration: none;
}
