.embedded .Settings-header a {
  display: none;
}
.Settings-header > div {
  margin: 0 1rem;
}
.Settings-header a {
  color: #147F74;
}
.Settings-header a > h1 {
  font-size: 2rem;
  margin: 0;
  color: #000;
}
.Settings-header a.active > h1 {
  font-size: 2rem;
  font-weight: bold;
}
@media only screen and (min-width:992px) {
  .Settings-header a.active > h1 {
    font-size: 50px;
  }
}
.Settings-main {
  margin: 1rem;
}
.Settings-main button {
  margin: auto;
  border-radius: 5px;
  border: 2px solid #147F74;
  color: #147F74;
  width: 100%;
  height: 40px;
  margin-bottom: 40px;
}
.Settings-main button:hover,
.Settings-main button:active,
.Settings-main button:focus {
  border-color: #147F74;
  color: #147F74;
}
.Settings-main .Settings-account,
.Settings-main .Settings-notification,
.Settings-main .Settings-language,
.Settings-main .Settings-preference-row {
  margin-top: 2rem;
  font-size: 1rem;
}
.Settings-main .Settings-header svg {
  color: rgba(0, 0, 0, 0.65);
}
.Settings-main .Settings-heading {
  margin-top: 0.5rem;
  font-size: 2rem;
  font-weight: bold;
}
.Settings-main .Settings-menu {
  margin-top: 1rem;
}
.Settings-main .Settings-menu a {
  color: inherit;
}
.Settings-main .Settings-menu .ant-list-item {
  font-size: 1.2rem;
  border-bottom: 1px solid #f0f0f0;
}
.Settings-main .Settings-account .ant-col span {
  font-size: 1rem;
}
.Settings-main .Settings-account .Settings-account-list {
  border-bottom: 1px solid #f0f0f0;
  font-size: 1rem;
  font-weight: bold;
}
.Settings-main .Settings-account .Settings-account-list span {
  color: #888;
  font-weight: initial;
  position: absolute;
  right: 0;
  font-size: initial;
}
.Settings-main .Settings-notification {
  line-height: 1.3;
}
.Settings-main .Settings-notification .ant-switch-handle {
  top: 5px;
}
.Settings-main .Settings-notification .ant-switch-handle:before {
  background-color: #147F74;
}
.Settings-main .Settings-notification .ant-switch,
.Settings-main .Settings-notification .ant-switch-checked {
  background-color: #FFF;
}
.Settings-main .Settings-notification .ant-switch::after,
.Settings-main .Settings-notification .ant-switch-checked::after {
  height: 29px;
  width: 29px;
}
.Settings-main .Settings-notification .ant-switch {
  height: 35px;
  width: 75px;
  border-color: #d9d9d9;
  border-radius: 100px;
}
.Settings-main .Settings-notification .ant-switch span {
  color: #a9a9a9;
}
.Settings-main .Settings-notification .ant-switch .ant-switch-inner {
  font-size: 16px;
}
.Settings-main .Settings-notification .ant-switch::after {
  background-color: #7b8989;
}
.Settings-main .Settings-notification .ant-switch-checked {
  border-color: #147F74;
}
.Settings-main .Settings-notification .ant-switch-checked span {
  color: #147F74;
}
.Settings-main .Settings-notification .ant-switch-checked .ant-switch-inner {
  margin-left: 0;
}
.Settings-main .Settings-notification .ant-switch-checked::after {
  background-color: #147F74;
}
.Settings-main .Settings-language .ant-list-item {
  border-bottom: 1px solid #d9d9d9;
}
.Settings-main .Settings-language .ant-list-item svg {
  color: #147F74;
}
.Settings-main .Settings-preference-row .ant-col {
  margin-top: 1rem;
}
.Settings-main .Settings-preference-row .ant-col .ant-card {
  border-radius: 5px;
}
.Settings-main .Settings-preference-row .ant-col .ant-card-head {
  padding: 0 1rem;
  font-weight: bold;
}
.Settings-main .Settings-preference-row .ant-col .ant-card-body {
  padding: 1rem;
}
.Settings-main .Settings-preference-row .ant-col .ant-card-body .ant-list-item {
  font-size: 1.1rem;
  padding: 10px 0;
}
.Settings-main .Settings-preference-row .ant-col .ant-card-body p {
  font-size: 1.1rem;
}
.Settings-main .Settings-preference-row .ant-col .ant-card-body .ResponsibleProfile-emptytext {
  font-size: 1.1rem;
}
.Settings-main .Settings-preference-row .ant-col:first-child {
  margin-top: 0;
}
.Settings-main .Settings-contact {
  margin-top: 1rem;
}
.Settings-main .Settings-contact > span {
  margin-bottom: 0.5rem;
  margin-left: 0.2rem;
  margin-right: 0.2rem;
}
.Settings-main .Settings-button {
  position: absolute;
  bottom: 0;
  left: 8px;
  width: 100%;
}
.Settings-main .Settings-button button {
  height: 47px;
}
@media only screen and (min-width:992px) {
  .Settings-main .Settings-button {
    position: relative;
    bottom: 0;
    text-align: center;
    padding: 5rem;
  }
}
.Settings-main .ant-list-item {
  cursor: pointer;
}
.ant-spin {
  margin: 50%;
  text-align: center;
}
@media only screen and (min-width:992px) {
  .ant-spin {
    margin: 10%;
  }
}
