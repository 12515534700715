.GlobalChat__chat {
  position: fixed;
  z-index: 1000;
  bottom: 0;
  right: 16px;
  overflow: visible;
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: nowrap;
  align-items: flex-end;
  width: 350px;
  box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.15);
  border-radius: 6px 6px 0 0;
  max-height: 500px;
}
.GlobalChat__chat.has-unread {
  border-color: #147F74;
}
.GlobalChat__chat.has-unread .ant-collapse-item {
  border-color: #147F74;
}
.GlobalChat__chat.has-unread .ant-collapse-header,
.GlobalChat__chat.has-unread .GlobalChat__userHeader {
  background: #147F74;
}
.GlobalChat__chat.has-unread .ant-collapse-header .GlobalChat__headerTitle,
.GlobalChat__chat.has-unread .GlobalChat__userHeader .GlobalChat__headerTitle,
.GlobalChat__chat.has-unread .ant-collapse-header .GlobalChat__newChat,
.GlobalChat__chat.has-unread .GlobalChat__userHeader .GlobalChat__newChat,
.GlobalChat__chat.has-unread .ant-collapse-header span.ant-collapse-arrow > svg,
.GlobalChat__chat.has-unread .GlobalChat__userHeader span.ant-collapse-arrow > svg {
  color: #FFF;
}
.GlobalChat .ps__thumb-y,
.GlobalChat .ps__thumb-x {
  background-color: rgba(20, 127, 116, 0.15);
}
.GlobalChat__userHeader {
  flex-grow: 2;
  position: relative;
  display: flex;
  align-items: center;
  background: #EBEAEA;
  border-radius: 6px 6px 0 0;
  transition: all 0.3s;
}
.GlobalChat__userHeader .AplanetAvatar {
  background-color: #FFF;
  width: 24px;
  height: 24px;
  min-width: 24px;
  min-height: 24px;
  margin-right: 8px;
  padding-top: 0;
}
.GlobalChat__userHeader .AplanetAvatar .ant-avatar-string {
  top: -15%;
}
.GlobalChat__userHeader .anticon {
  cursor: pointer;
  color: #147F74;
}
.GlobalChat__userHeader .anticon > svg {
  width: 16px;
  height: 16px;
}
.GlobalChat__closeChat.anticon {
  position: absolute;
  left: 300px;
  top: 5px;
}
.GlobalChat__closeChat.anticon > svg {
  width: 14px;
  height: 14px;
}
.GlobalChat__headerTotalUnread {
  position: absolute;
  font-weight: bold;
  font-size: 13px;
  padding: 3px 5px;
  border-radius: 12px;
  background: #C13838;
  color: #FFF;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  z-index: 100;
  width: auto;
  height: 24px;
  min-width: 24px;
  left: 320px;
  text-align: center;
  bottom: 20px;
}
.GlobalChat__headerTitle {
  font-size: 16px;
  color: #000;
  order: initial;
  flex-grow: 2;
}
.GlobalChat__search {
  padding: 8px;
  font-size: 14px;
}
.GlobalChat__search .ant-input-affix-wrapper {
  background: #FFF;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  border: none;
  min-width: 300px;
}
.GlobalChat__chatsList .cs-conversation--active,
.GlobalChat__chatsList .cs-conversation--active:active {
  color: #000;
  background-color: rgba(20, 127, 116, 0.15);
}
.GlobalChat__conversation {
  flex-direction: row;
  padding: 16px;
}
.GlobalChat__conversation .AplanetAvatar {
  width: 32px;
  height: 32px;
  min-width: 32px;
  min-height: 32px;
  margin-right: 8px;
  padding-top: 0;
  background-color: #FFF;
}
.GlobalChat__conversationLastTimeWrapper {
  margin-left: 4px;
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-items: center;
}
.GlobalChat__conversationLastTime {
  font-size: 10px;
  color: #5A6462;
  align-self: flex-start;
  margin-top: 4px;
}
.GlobalChat__conversationUnread {
  border-radius: 9px;
  padding: 2px 4px 0 4px;
  background: #C13838;
  color: #FFF;
  font-size: 10px;
  width: fit-content;
  min-width: 18px;
  height: 18px;
  text-align: center;
}
.GlobalChat__conversationContent {
  flex-direction: row;
  align-items: center;
  margin-right: 0;
}
.GlobalChat__conversationWrapper {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  flex-grow: 1;
}
.GlobalChat__conversationName {
  font-weight: bold;
  font-size: 14px;
  color: #23302E;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.GlobalChat__conversationInfo {
  color: #23302E;
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.GlobalChat__conversationLastSender {
  display: inline;
}
.GlobalChat__conversationLastMessage {
  margin-left: 4px;
  display: inline;
}
.GlobalChat__conversationNoMessage {
  color: #147F74;
  font-size: 14px;
}
.GlobalChat__chatWindow,
.GlobalChat__chatUsersListWindow {
  z-index: 1000;
  position: fixed;
  bottom: 0;
  right: 380px;
  max-height: 500px;
  width: 350px;
  box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.15);
  border-radius: 6px 6px 0 0;
}
.GlobalChat__chatWindow .ant-collapse-header .anticon.ant-collapse-arrow,
.GlobalChat__chatUsersListWindow .ant-collapse-header .anticon.ant-collapse-arrow {
  right: 40px;
  z-index: 10;
}
.GlobalChat__chatWindow.has-unread {
  border-color: #147F74;
}
.GlobalChat__chatWindow.has-unread .ant-collapse-item {
  border-color: #147F74;
}
.GlobalChat__chatWindow.has-unread .ant-collapse-header,
.GlobalChat__chatWindow.has-unread .GlobalChat__userHeader {
  background: #147F74;
}
.GlobalChat__chatWindow.has-unread .ant-collapse-header .GlobalChat__headerTitle,
.GlobalChat__chatWindow.has-unread .GlobalChat__userHeader .GlobalChat__headerTitle,
.GlobalChat__chatWindow.has-unread .ant-collapse-header .GlobalChat__closeChat,
.GlobalChat__chatWindow.has-unread .GlobalChat__userHeader .GlobalChat__closeChat,
.GlobalChat__chatWindow.has-unread .ant-collapse-header span.ant-collapse-arrow > svg,
.GlobalChat__chatWindow.has-unread .GlobalChat__userHeader span.ant-collapse-arrow > svg {
  color: #FFF;
}
.GlobalChat__firstLetter {
  background: #EBEAEA;
  font-weight: bold;
  font-size: 12px;
  color: #000;
  padding-left: 16px;
}
.GlobalChat__userListWrapper {
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 0 16px;
  margin: 8px 0;
}
.GlobalChat__userList {
  font-weight: bold;
  font-size: 14px;
  color: #23302E;
}
.GlobalChat__chatUsersList {
  overflow: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.GlobalChat__chatUsersList::-webkit-scrollbar {
  display: none;
}
.GlobalChat__chatConversation,
.GlobalChat__chatUsersList {
  height: 100%;
}
.GlobalChat__chatConversation .EmptySearch,
.GlobalChat__chatUsersList .EmptySearch {
  padding-left: 16px;
  padding-right: 16px;
}
.GlobalChat__chatConversation .cs-typing-indicator,
.GlobalChat__chatUsersList .cs-typing-indicator {
  background: transparent;
}
.GlobalChat__chatConversation .cs-typing-indicator__dot,
.GlobalChat__chatUsersList .cs-typing-indicator__dot {
  background-color: #147F74;
}
.GlobalChat__chatConversation .cs-typing-indicator__text,
.GlobalChat__chatUsersList .cs-typing-indicator__text {
  color: #147F74;
}
.GlobalChat__chatConversation .cs-message-group--outgoing .cs-message,
.GlobalChat__chatUsersList .cs-message-group--outgoing .cs-message,
.GlobalChat__chatConversation .cs-message-group--outgoing .cs-message__content-wrapper,
.GlobalChat__chatUsersList .cs-message-group--outgoing .cs-message__content-wrapper,
.GlobalChat__chatConversation .cs-message-group--outgoing .cs-message__content,
.GlobalChat__chatUsersList .cs-message-group--outgoing .cs-message__content {
  border-radius: 6px 6px 2px 6px;
}
.GlobalChat__chatConversation .cs-message-group--outgoing .cs-message .cs-message__content-wrapper .cs-message__content,
.GlobalChat__chatUsersList .cs-message-group--outgoing .cs-message .cs-message__content-wrapper .cs-message__content {
  border-radius: 6px 6px 2px 6px;
  background: rgba(20, 127, 116, 0.15);
}
.GlobalChat__chatConversation .cs-message-group--outgoing .cs-message-group__messages,
.GlobalChat__chatUsersList .cs-message-group--outgoing .cs-message-group__messages {
  justify-content: flex-end;
  align-items: flex-end;
}
.GlobalChat__chatConversation .cs-message-group--incoming .cs-message,
.GlobalChat__chatUsersList .cs-message-group--incoming .cs-message,
.GlobalChat__chatConversation .cs-message-group--incoming .cs-message__content-wrapper,
.GlobalChat__chatUsersList .cs-message-group--incoming .cs-message__content-wrapper,
.GlobalChat__chatConversation .cs-message-group--incoming .cs-message__content,
.GlobalChat__chatUsersList .cs-message-group--incoming .cs-message__content {
  border-radius: 2px 6px 6px 6px;
}
.GlobalChat__chatConversation .cs-message-group--incoming .cs-message .cs-message__content-wrapper .cs-message__content,
.GlobalChat__chatUsersList .cs-message-group--incoming .cs-message .cs-message__content-wrapper .cs-message__content {
  border-radius: 2px 6px 6px 6px;
  background: #FFF;
}
.GlobalChat__chatConversation .cs-message-group .cs-message,
.GlobalChat__chatUsersList .cs-message-group .cs-message {
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
  width: fit-content;
  box-sizing: border-box;
  font-size: 15px;
  color: #23302E;
  border: none;
  background: transparent;
}
.GlobalChat__chatConversation .cs-message-group .cs-message:not(:only-child),
.GlobalChat__chatUsersList .cs-message-group .cs-message:not(:only-child) {
  margin-top: 4px;
}
.GlobalChat__chatConversation .cs-message-group .cs-message-group__messages,
.GlobalChat__chatUsersList .cs-message-group .cs-message-group__messages {
  display: flex;
  flex-direction: column;
}
.GlobalChat__chatConversation .cs-message-group .cs-message__html-content,
.GlobalChat__chatUsersList .cs-message-group .cs-message__html-content {
  color: #23302E;
}
.GlobalChat__chatConversation .cs-message-input,
.GlobalChat__chatUsersList .cs-message-input {
  border: none;
  background: #FFF;
  box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.15);
}
.GlobalChat__chatConversation .cs-message-input .cs-button--send,
.GlobalChat__chatUsersList .cs-message-input .cs-button--send {
  color: #147F74;
}
.GlobalChat__chatConversation .cs-message-input .cs-button--send:disabled,
.GlobalChat__chatUsersList .cs-message-input .cs-button--send:disabled {
  opacity: rgba(20, 127, 116, 0.7);
}
.GlobalChat__chatConversation .cs-message-input .cs-message-input__content-editor-wrapper,
.GlobalChat__chatUsersList .cs-message-input .cs-message-input__content-editor-wrapper {
  background: transparent;
  font-size: 15px;
  color: #23302E;
}
.GlobalChat__chatConversation .cs-message-input .cs-message-input__content-editor-container,
.GlobalChat__chatUsersList .cs-message-input .cs-message-input__content-editor-container,
.GlobalChat__chatConversation .cs-message-input div.cs-message-input__content-editor,
.GlobalChat__chatUsersList .cs-message-input div.cs-message-input__content-editor {
  background-color: transparent;
}
.GlobalChat__messageList {
  background: #FBFBFB;
}
.GlobalChat__messageSeparator {
  width: 320px;
  color: #5A6462;
  background: transparent;
  font-size: 14px;
}
.GlobalChat__messageSeparator::before,
.GlobalChat__messageSeparator::after {
  box-sizing: border-box;
  content: "";
  background-color: #EBEAEA;
  display: block;
  flex-grow: 1;
  height: 1px;
}
.GlobalChat .ant-spin {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin: 0;
}
.GlobalChat .ant-spin .ant-spin-dot-item {
  background-color: #5A6462;
}
.GlobalChat .ant-collapse-item {
  background: #FFF;
  width: 100%;
}
.GlobalChat .ant-collapse-item .ant-collapse-header .ant-collapse-arrow {
  transform: rotate(-90deg);
  top: auto;
  margin-left: 10px;
}
.GlobalChat .ant-collapse-item .ant-collapse-header .ant-collapse-arrow > svg {
  color: #147F74;
  width: 16px;
  height: 16px;
}
.GlobalChat .ant-collapse-item-active {
  border-radius: 6px 6px 0 0;
  height: 450px;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.15);
}
.GlobalChat .ant-collapse-item-active .ant-collapse-header {
  box-shadow: none;
}
.GlobalChat .ant-collapse-item-active .ant-collapse-header .ant-collapse-arrow {
  transform: rotate(0deg);
}
.GlobalChat .ant-collapse-header {
  display: flex;
  align-items: center;
  padding-left: 16px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
  background: #EBEAEA;
  border-radius: 6px 6px 0 0;
}
.GlobalChat .ant-collapse-content-active {
  flex-grow: 2;
}
.GlobalChat .ant-collapse-content-box {
  height: 100%;
}
.GlobalChat .ant-collapse-content .cs-conversation__content {
  flex-direction: row;
  align-items: center;
}
.GlobalChat .ant-collapse-content .cs-conversation__content .AplanetAvatar {
  width: 32px;
  height: 32px;
  min-width: 32px;
  min-height: 32px;
  margin-right: 8px;
  padding-top: 0;
}
.GlobalChat .ant-collapse {
  border-radius: 6px 6px 0 0;
}
.GlobalChat .ant-collapse-header {
  height: 40px;
}
.GlobalChat .ant-collapse-content {
  height: 100%;
}
.GlobalChat .ant-collapse-content-box {
  padding: 0;
}
.GlobalChat .ant-collapse > .ant-collapse-item:last-child,
.GlobalChat .ant-collapse > .ant-collapse-item:last-child > .ant-collapse-header {
  border-radius: 6px 6px 0 0;
}
.GlobalChat__totalUnreadBadge {
  position: fixed;
  bottom: 100px;
  right: 30px;
  font-weight: bold;
  font-size: 12px;
  padding: 3px 5px;
  border-radius: 12px;
  background: #C13838;
  color: #FFF;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  z-index: 100;
  width: auto;
  height: 24px;
  min-width: 24px;
  text-align: center;
}
.GlobalChat .FloatingButton.GlobalChat__floatingButton {
  bottom: 76px;
  border-radius: 50%;
}
.GlobalChat__chatFullScreen {
  position: fixed;
  z-index: 1000;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  min-width: 100%;
  max-width: 100%;
  width: 100vw;
  height: 100vh;
  overflow: visible;
  background: #FFF;
}
.GlobalChat__chatFullScreen .ps__thumb-y,
.GlobalChat__chatFullScreen .ps__thumb-x {
  background-color: rgba(20, 127, 116, 0.15);
}
.GlobalChat__goBack {
  margin-right: 16px;
}
@media screen and (max-width: 768px) {
  .GlobalChat__userHeader {
    height: 88px;
    padding: 0 16px;
  }
  .GlobalChat__userHeader .anticon > svg {
    width: 20px;
    height: 20px;
  }
  .GlobalChat__userHeader .GlobalChat__closeChat {
    margin-left: 16px;
    position: relative;
    top: 0;
    left: 0;
  }
  .GlobalChat__userHeader .GlobalChat__closeChat > svg {
    width: 18px;
    height: 18px;
  }
  .GlobalChat__headerTotalUnread {
    display: none;
  }
  .GlobalChat__headerTitle {
    font-weight: bold;
    font-size: 24px;
    color: #000;
  }
  .GlobalChat .AplanetAvatar {
    width: 40px;
    height: 40px;
    min-width: 40px;
    min-height: 40px;
    margin-right: 8px;
    padding-top: 0;
  }
  .GlobalChat .AplanetAvatar .ant-avatar-string {
    top: 15%;
  }
  .GlobalChat__chatsList,
  .GlobalChat__chatUsersList,
  .GlobalChat__chatConversation {
    height: calc(100vh - 88px);
  }
  .GlobalChat__conversationLastTime {
    font-size: 12px;
  }
  .GlobalChat__conversationContent .AplanetAvatar {
    width: 40px;
    height: 40px;
    min-width: 40px;
    min-height: 40px;
    margin-right: 8px;
    padding-top: 0;
  }
  .GlobalChat__conversationName {
    font-size: 16px;
  }
  .GlobalChat__chatConversation .cs-message__content-wrapper {
    background-color: #FFF;
  }
  .GlobalChat__chatConversation div.cs-message-input {
    margin: 0;
    background: #EBEAEA;
    padding: 8px 0;
  }
  .GlobalChat__chatConversation div.cs-message-input .cs-message-input__content-editor-wrapper,
  .GlobalChat__chatConversation div.cs-message-input .cs-message-input__content-editor {
    background-color: #FFF;
  }
  .GlobalChat__search {
    font-size: 15px;
  }
  .GlobalChat__firstLetter,
  .GlobalChat__userList {
    font-size: 16px;
  }
  .GlobalChat__messageList .ant-spin-dot-item {
    background: #FFF;
  }
  .GlobalChat__messageList div.cs-typing-indicator__dot {
    background-color: #FFF;
  }
  .GlobalChat__messageList div.cs-typing-indicator__text {
    color: #FFF;
  }
  .GlobalChat__messageSeparator.cs-message-separator {
    width: 95vw;
  }
}
.App.ios .GlobalChat__chatFullScreen .GlobalChat__chatFullScreenHeaderWrapper {
  padding-top: 25px;
  background: #EBEAEA;
}
.App.ios .GlobalChat__chatFullScreen .GlobalChat__chatsList,
.App.ios .GlobalChat__chatFullScreen .GlobalChat__chatUsersList,
.App.ios .GlobalChat__chatFullScreen .GlobalChat__chatConversation {
  height: calc(100vh - 112px);
}
