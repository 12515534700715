.SignIn input {
  width: 90%;
  margin: 5px 20px 20px 20px;
  height: 40px;
}
.SignIn label {
  margin-left: 20px;
}
.SignIn button.cta {
  width: 90%;
  margin: 20px;
  position: absolute;
  bottom: 20px;
}
@media only screen and (min-width:576px) {
  .SignIn button.cta {
    width: 60%;
    margin: 5% 20%;
  }
}
.SignIn .ant-col {
  position: static;
}
@media only screen and (min-width:576px) {
  .SignIn .ant-col {
    position: relative;
  }
}
.SignIn .forgot-password {
  margin-left: 20px;
}
.SignIn .header {
  height: 25vh;
  padding: 20px;
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
  background-color: #147F74;
  background-size: cover;
}
.SignIn .header h1 {
  font-size: 3.2em;
  color: #fff;
  margin: 0;
  width: 100%;
  font-weight: bold;
}
.SignIn .header h4 {
  color: #fff;
}
.SignIn .header svg {
  color: #fff;
}
@media only screen and (min-width:576px) {
  .SignIn .header {
    background-image: initial !important;
  }
}
.SignIn .success {
  margin-top: 5px;
  color: #00B46E;
}
.SignIn .cover {
  min-height: 100vh;
}
