.Avatar {
  border-radius: 50%;
  background: #ddd;
  overflow: hidden;
  width: 100%;
  height: auto;
  position: relative;
  padding-top: 100%;
}
.Avatar .Avatar-initials {
  display: block;
  max-width: 100%;
  max-height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  align-items: center;
  justify-content: center;
  display: flex;
}
.Avatar img {
  display: block;
  max-width: 100%;
  max-height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.Avatar svg {
  display: block;
  width: 100% !important;
  height: 100%;
  max-width: 100%;
  max-height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
