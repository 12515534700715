.SignUp-fullheight {
  min-height: 100vh;
  min-height: calc(var(--vh, 1vh) * 100);
}
.SignUp-aplanetlogo {
  max-width: 128px;
  margin-top: 15px;
  margin-bottom: 15px;
  filter: brightness(0) invert(1);
}
.SignUp-corporatelogo {
  max-height: 20vh;
  margin-top: 15px;
  margin-bottom: 15px;
  display: flex;
  justify-content: center;
}
.SignUp-corporatelogo img {
  object-fit: contain;
  display: block;
}
.SignUp-choosen-email {
  margin-bottom: 24px;
}
.SignUp-publicorglist {
  width: 100%;
}
.SignUp-publicorglist .ImageCheckbox {
  margin-right: 5px;
}
.SignUp-header {
  height: 30vh;
  padding: 20px;
}
.SignUp-header h1 {
  font-size: 3.2em;
  color: #fff;
  margin: 0;
  width: 100%;
  font-weight: bold;
}
.SignUp-header h4 {
  color: #fff;
}
.SignUp-header svg {
  color: #fff;
}
@media only screen and (min-width:576px) {
  .SignUp-header {
    background: #147F74 !important;
  }
}
.SignUp .SignUp-input label,
.SignUp-password .SignUp-input label {
  margin-left: 20px;
}
.SignUp .SignUp-input input,
.SignUp-password .SignUp-input input {
  width: 90%;
  margin: 5px 20px 20px 20px;
  height: 40px;
}
.SignUp .SignUp-input .ant-input-affix-wrapper,
.SignUp-password .SignUp-input .ant-input-affix-wrapper {
  margin: 5px 20px 20px 20px;
  width: 90%;
}
.SignUp .SignUp-input .ant-input-affix-wrapper input,
.SignUp-password .SignUp-input .ant-input-affix-wrapper input {
  width: 100%;
  margin: initial;
}
.SignUp .SignUp-checkboxes .ant-checkbox-wrapper,
.SignUp-password .SignUp-checkboxes .ant-checkbox-wrapper {
  margin: 2px 20px;
}
.SignUp .SignUp-checkboxes a,
.SignUp-password .SignUp-checkboxes a {
  color: #007AFF;
}
.SignUp .SignUp-bottomlink,
.SignUp-password .SignUp-bottomlink {
  margin: 10px 20px;
}
.SignUp button.cta,
.SignUp-password button.cta {
  width: 90%;
  margin: 20px;
}
@media only screen and (min-width:576px) {
  .SignUp button.cta,
  .SignUp-password button.cta {
    width: 60%;
    margin: 5% 20%;
    position: static;
    bottom: 20px;
  }
}
.SignUp .ant-col,
.SignUp-password .ant-col {
  position: static;
}
@media only screen and (min-width:576px) {
  .SignUp .ant-col,
  .SignUp-password .ant-col {
    position: relative;
  }
}
.SignUp .header,
.SignUp-password .header {
  height: 30vh;
  padding: 20px;
}
.SignUp .header h1,
.SignUp-password .header h1 {
  font-size: 3.2em;
  color: #fff;
  margin: 0;
  width: 100%;
  font-weight: bold;
}
.SignUp .header h4,
.SignUp-password .header h4 {
  color: #fff;
}
.SignUp .header svg,
.SignUp-password .header svg {
  color: #fff;
}
@media only screen and (min-width:576px) {
  .SignUp .header,
  .SignUp-password .header {
    background: #147F74 !important;
  }
}
.SignUp .cover,
.SignUp-organization .cover,
.SignUp-password .cover {
  min-height: 100vh;
}
.SignUp-password .header {
  height: 30vh;
  padding: 20px;
}
.SignUp-password .header h1 {
  font-size: 3.2em;
  color: #fff;
  margin: 0;
  width: 100%;
  font-weight: bold;
}
.SignUp-password .header h4 {
  color: #fff;
}
.SignUp-password .header svg {
  color: #fff;
}
@media only screen and (min-width:576px) {
  .SignUp-password .header {
    background: #147F74 !important;
  }
}
.SignUp-organization .ant-alert {
  width: 100%;
}
.SignUp-organization h1 {
  padding: 10px;
  font-size: 3.1em;
  line-height: 1.1em;
  font-weight: bold;
  margin: 0;
  width: 350px;
}
.SignUp-organization .SignUp-publicorglist {
  padding: 0 10px;
}
.SignUp-SsoConfirmationSignUp,
.SignUp-emailConfirmation {
  text-align: center;
}
.SignUp-SsoConfirmationSignUp > .ant-col > .ant-row,
.SignUp-emailConfirmation > .ant-col > .ant-row {
  padding-top: 100px;
}
.SignUp-SsoConfirmationSignUp .NavTopBack,
.SignUp-emailConfirmation .NavTopBack {
  position: absolute;
}
.CorporateSignUp-content {
  padding-top: 20px;
}
