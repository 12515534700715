.ant-menu-vertical {
  border: none;
}
.ant-menu-item > a:hover {
  color: #273941;
}
.ant-menu-vertical .ant-menu-item {
  padding-left: 20px;
  margin-left: 28px;
  padding-right: 26px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  width: fit-content;
}
.ant-menu-vertical .ant-menu-item > a {
  display: flex;
  align-items: center;
}
.ant-menu-vertical .ant-menu-item:hover {
  background: rgba(39, 57, 65, 0.07);
}
.ant-menu-vertical .ant-menu-item svg {
  float: left;
}
.ant-menu-vertical .ant-menu-item .navLabel {
  font-size: 17px;
  min-width: 20px;
  color: #273941;
}
.ant-menu-vertical .ant-menu-item.ant-menu-item-selected {
  background-color: rgba(20, 127, 116, 0.15);
}
.ant-menu-vertical .ant-menu-item.ant-menu-item-selected .navLabel {
  font-size: 17px;
  min-width: 20px;
  font-weight: bold;
  color: #147F74;
}
.ant-menu-inline-collapsed .ant-menu-item {
  margin-left: 5px;
}
.ant-menu-item a::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: transparent;
  content: '';
}
.about-our-program-icon svg {
  margin-right: 0px;
  height: 20px;
  width: 20px;
}
