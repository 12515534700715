.hamburger-drawer .ant-drawer-body {
  padding-left: 0;
}
.hamburger-menu {
  display: none;
}
.hamburger-menu svg {
  font-size: 26px;
  margin-top: 10px;
  margin-left: 18px;
}
.embedded .hamburger-menu {
  display: inline;
}
@media only screen and (min-width:992px) {
  .embedded .hamburger-menu {
    display: none;
  }
}
.ant-layout-header {
  height: 45px;
  background: #fff;
  padding: 0px;
  overflow: hidden;
}
.ant-layout-header .header-logo .hamburger-menu svg {
  margin-bottom: 40px;
}
.ant-layout-header .header-logo a {
  display: inline-block;
}
.ant-layout-header .organization-logo {
  margin: 10px 0px 0px 15px;
  height: 72px;
  overflow: hidden;
  max-width: 45%;
}
@media only screen and (min-width:992px) {
  .ant-layout-header .organization-logo {
    height: 100px;
    margin-left: 40px;
  }
}
.ant-layout-header .Wall-avatar {
  display: none;
  width: 42px;
  height: 42px;
  margin-right: 35px;
  margin-bottom: 35px;
}
@media only screen and (min-width:992px) {
  .ant-layout-header .Wall-avatar {
    display: block;
  }
}
@media only screen and (min-width:992px) {
  .ant-layout-header {
    height: 100px;
  }
}
