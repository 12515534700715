.ParticipationScreen-qr-header {
  text-align: center;
  font-weight: bold;
  font-size: larger;
  margin: 10px;
}
.ParticipationScreen-qr {
  display: flex;
  align-items: center;
  justify-content: center;
}
.ParticipationScreen-qr svg {
  width: 90%;
  height: auto;
}
.ParticipationScreen-status-pending {
  color: #FFC600;
}
.ParticipationScreen-status-rejected {
  color: #D65050;
}
.ParticipationScreen-status-validated,
.ParticipationScreen-status-granted {
  color: #42B7A6;
}
.ParticipationScreen-list .ant-list-item {
  border: 0.1px solid #efefef;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 5px 5px 8px -10px;
  margin-bottom: 10px;
}
.ParticipationScreen-list .ant-list-item h4 {
  font-weight: bold;
  font-size: 1rem;
}
.ParticipationScreen-list .ant-list-item .ant-list-item-meta-description {
  font-size: 0.8rem;
}
.ParticipationScreen .affix-button .ant-affix button {
  width: 100%;
}
.ParticipationScreen .affix-button {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
}
.ParticipationScreen-header {
  height: 55px;
  padding-left: 8px;
}
.ParticipationScreen-header a {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.ParticipationScreen-header a * {
  color: #000;
}
.ParticipationScreen-header a svg {
  margin-right: 10px;
  margin-bottom: -2px;
}
.ParticipationScreen-header a h2 {
  display: inline-block;
}
.ParticipationScreen-report .header {
  text-align: center;
  font-weight: bold;
  font-size: larger;
  margin: 10px 0 20px 0;
}
.ParticipationScreen-report .ant-input-affix-wrapper {
  height: 45px;
  max-height: 45px;
  border-radius: 7px;
  margin-bottom: 15px;
}
.ParticipationScreen-report .ant-input-affix-wrapper:hover {
  box-shadow: none;
}
.ParticipationScreen-report .ant-input-affix-wrapper .ant-input-prefix svg {
  height: 20px;
  width: 20px;
}
.ParticipationScreen-report .ant-input-affix-wrapper input {
  padding-left: 5px;
}
.ParticipationScreen-report .ant-input-affix-wrapper input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.ParticipationScreen-report .ant-input-affix-wrapper input[type=number] {
  -moz-appearance: textfield;
}
.ParticipationScreen-report .ant-input-affix-wrapper button {
  border: 2px solid #117065;
  background: #147F74;
  border-radius: 5px;
  font-weight: bold;
  font-size: 13px;
  color: #fff;
  height: 100%;
}
.ParticipationScreen-report .ant-input-affix-wrapper button:disabled {
  opacity: 0.5;
}
