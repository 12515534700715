.PreSignIn {
  min-height: 100vh;
  height: 100%;
}
.PreSignIn .ant-col {
  text-align: center;
}
.PreSignIn .heading {
  color: #fff;
  padding: 15px;
  font-size: 3.5em;
  line-height: 1em;
  font-weight: 500;
  text-align: left;
  display: block;
  margin-bottom: 15px;
  max-width: 700px;
  text-shadow: -3px 1px 0px #060606;
}
@media only screen and (min-width:992px) {
  .PreSignIn .heading {
    font-size: 5em;
  }
}
.PreSignIn button {
  width: 90%;
  margin: 0 20px 10px 20px;
}
@media only screen and (min-width:992px) {
  .PreSignIn button {
    width: 300px;
    margin-bottom: 50px;
  }
}
