.ProposeInitiative-header {
  margin: 1rem;
  margin-bottom: 0;
  position: relative;
}
.ProposeInitiative-header h1 {
  color: #000;
  font-size: 2rem;
  font-weight: bold;
  margin: 0;
}
@media only screen and (min-width:992px) {
  .ProposeInitiative-header h1 {
    font-size: 50px;
  }
}
.ProposeInitiative-header svg {
  color: #000;
}
.ProposeInitiative-header .ProposeInitiative-close {
  position: absolute;
  right: 0;
  top: 0;
}
.ProposeInitiative-header .down {
  top: 10px;
}
.ProposalInitiative-steps {
  color: #a9a9a9;
  font-size: 18px;
  margin: 0 1rem;
}
