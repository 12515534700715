.checkbox-row {
  border: 2px solid #d9d9d9;
  border-radius: 5px;
  height: 52px;
  margin-bottom: 0.5rem;
  padding: 9px 15px;
  cursor: pointer;
}
.checkbox-row .header {
  line-height: 1.2;
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.checkbox-row .header svg {
  margin-right: 10px;
}
.checkbox-row .checkbox {
  text-align: end;
}
.checkbox-row .ant-checkbox:hover::after {
  visibility: hidden;
}
.checkbox-row .ant-checkbox-inner {
  background-color: #d9d9d9;
  border: 2px solid #d9d9d9;
  border-radius: 50%;
}
.checkbox-row .ant-checkbox-checked .ant-checkbox-inner {
  border-color: #147F74;
  background-color: #147F74;
}
.selected {
  border: 2px solid #147F74;
  color: #147F74;
}
