.RankingCard-avatar {
  display: inline-block;
  width: 30px;
  height: 30px;
}
.RankingCard-avatar img {
  width: 100%;
  height: 100%;
}
.RankingCard-avatar .Avatar {
  margin-top: 3px;
}
@media only screen and (min-width:992px) {
  .RankingCard-avatar {
    width: 40px;
    height: 40px;
  }
}
.RankingCard-Top {
  text-align: center;
  margin-left: 1rem;
}
.RankingCard-Top h2 {
  font-size: 28px;
  margin: auto;
  width: 100%;
}
.RankingCard-Top span {
  position: relative;
  bottom: 150px;
  right: 45px;
  font-size: 35px;
  font-weight: normal;
  text-align: start;
  color: #000;
  float: left;
}
.RankingCard-Top .RankingCard-avatar {
  width: 150px;
  height: 150px;
  max-width: 150px;
  max-height: 150px;
}
.RankingCard-Top .RankingCard-name-top svg {
  color: #FFCC00;
  margin-right: 10px;
}
.RankingCard-Top .RankingCard-hours-top {
  font-weight: normal;
  margin-bottom: 2rem;
}
.RankingCard-row {
  color: #4D4E4F;
  font-size: 15px;
  font-weight: normal;
  width: 100%;
  height: 60px;
}
.RankingCard-row .RankingCard-name {
  position: absolute;
  bottom: 8px;
  left: 45px;
}
@media only screen and (min-width:992px) {
  .RankingCard-row .RankingCard-name {
    bottom: 13px;
  }
}
.RankingCard-row .RankingCard-list-rank {
  color: #a9a9a9;
  margin-left: 1rem;
  font-size: 12px;
}
@media only screen and (min-width:992px) {
  .RankingCard-row .RankingCard-list-rank {
    font-size: 15px;
  }
}
.RankingCard-row .RankingCard-list-hour {
  text-align: end;
  font-weight: bold;
}
.RankingCard-row .RankingCard-list-hour span {
  margin-right: 1rem;
}
.RankingCard-row:nth-child(even) {
  background-color: #F6F7F7;
}
.RankingCard-row:nth-child(odd) {
  background-color: #ffffff;
}
