.Onboarding__modal {
  width: 100% !important;
  height: 100%;
  max-width: 100%;
  padding: 0;
  margin: 0;
  top: 0;
  background-color: #FFF;
}
.Onboarding__modal .ant-modal-content {
  box-shadow: none;
  height: 100%;
}
.Onboarding__modal .ant-modal-content .ant-modal-close {
  top: 0.5rem;
  right: -0.5rem;
}
.Onboarding__modal .ant-modal-content .ant-modal-header {
  padding: 1rem;
  border-bottom: none;
}
.Onboarding__modal .ant-modal-content .ant-modal-header .ant-modal-title {
  font-weight: bold;
  font-size: 2rem;
  line-height: 1;
}
.Onboarding__modal .ant-modal-content .ant-modal-body {
  padding: 1rem;
  height: 75vh;
  overflow-y: auto;
}
@media only screen and (min-width:576px) {
  .Onboarding__modal .ant-modal-content .ant-modal-body {
    height: 83vh;
  }
}
@media only screen and (min-width:992px) {
  .Onboarding__modal .ant-modal-content .ant-modal-body {
    height: 65vh;
  }
}
.Onboarding__modal .ant-modal-content .Onboarding__modal-inner textarea {
  border-radius: 5px;
}
.Onboarding__modal .ant-modal-content .Onboarding__modal-inner textarea:hover,
.Onboarding__modal .ant-modal-content .Onboarding__modal-inner textarea:focus,
.Onboarding__modal .ant-modal-content .Onboarding__modal-inner textarea:active {
  border-color: #a9a9a9;
  box-shadow: 0 0 0 2px rgba(61, 61, 61, 0.2);
}
.Onboarding__modal .ant-modal-content .Onboarding__modal-inner .Onboarding__modal-details-row > span {
  font-weight: bold;
}
.Onboarding__modal .ant-modal-content .Onboarding__modal-inner .Onboarding__modal-details-row .ant-col {
  margin-bottom: 1rem;
}
.Onboarding__modal .ant-modal-content .ant-modal-footer {
  text-align: center;
  position: absolute;
  bottom: 0;
  width: 100%;
  background-color: white;
}
.Onboarding__modal .ant-modal-content .ant-modal-footer button {
  width: 48%;
  border: 1px solid #147F74;
  border-radius: 5px;
  color: #147F74;
}
.Onboarding__modal .ant-modal-content .ant-modal-footer button:hover,
.Onboarding__modal .ant-modal-content .ant-modal-footer button:focus,
.Onboarding__modal .ant-modal-content .ant-modal-footer button:active {
  color: #147F74;
}
.Onboarding__modal .ant-modal-content .ant-modal-footer .ant-btn-primary {
  color: #FFF;
  background-color: #147F74;
}
.Onboarding__modal .ant-modal-content .ant-modal-footer .ant-btn-primary:hover,
.Onboarding__modal .ant-modal-content .ant-modal-footer .ant-btn-primary:focus,
.Onboarding__modal .ant-modal-content .ant-modal-footer .ant-btn-primary:active {
  color: #FFF;
}
.Onboarding__modal .ant-modal-content .ant-modal-footer .ant-btn-primary[disabled] {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
  text-shadow: none;
}
@media only screen and (min-width:992px) {
  .Onboarding__modal .ant-modal-content .ant-modal-footer {
    position: relative;
  }
}
@media only screen and (min-width:992px) {
  .Onboarding__modal {
    height: initial;
    width: 520px !important;
    margin: initial;
    top: 100px;
    padding: initial;
    margin: auto;
    left: initial;
  }
}
