.SdgLogo {
  background: transparent;
  width: 100%;
}
.SdgLogo img {
  object-fit: contain;
  display: block;
  max-width: 100%;
  max-height: 100%;
}
.SdgLogo-square {
  overflow: hidden;
  width: 100%;
  height: auto;
  position: relative;
  padding-top: 100%;
}
.SdgLogo-square img {
  display: block;
  max-width: 100%;
  max-height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.relative {
  position: relative;
}
.overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 2;
  cursor: pointer;
  text-align: center;
  padding-top: 1.5rem;
  font-size: 2rem;
}
.overlay svg {
  color: #FFF;
}
