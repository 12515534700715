.embedded .NavBottom-wrapper {
  display: none;
}
.NavBottom-wrapper {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9;
}
.NavBottom-placeholder {
  height: 50px;
  display: block;
  width: 100%;
  background: transparent;
}
.NavBottom {
  position: fixed;
  width: 100%;
  max-width: 1024px;
  bottom: 0;
}
.NavBottom .profile {
  height: 25px;
  width: 25px;
  object-fit: cover;
  border-radius: 50%;
  filter: grayscale(100%);
}
.NavBottom .profile-selected {
  height: 25px;
  width: 25px;
  object-fit: cover;
  border-radius: 50%;
  filter: grayscale(100%);
  filter: grayscale(0%);
  color: #147F74 !important;
}
.NavBottom .am-tab-bar-tab-icon svg {
  color: #273941;
}
.NavBottom .am-tab-bar-tab-icon svg.active {
  color: #147F74 !important;
}
