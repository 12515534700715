.padding-md-x {
  padding: 0 16px 0 16px;
}
.Terms-modal {
  width: 80% !important;
  border-radius: 5px;
}
.Terms-modal .ant-modal-title {
  font-weight: bold;
}
.Terms-modal .ant-btn {
  border-color: #147F74;
  color: #147F74;
  border-radius: 5px;
}
.Terms-modal .ant-btn:hover,
.Terms-modal .ant-btn:focus,
.Terms-modal .ant-btn:active {
  border-color: #147F74;
  color: #147F74;
}
.Terms-modal .ant-btn-primary {
  background-color: #147F74;
  color: #FFF;
}
.Terms-modal .ant-btn-primary:hover,
.Terms-modal .ant-btn-primary:focus,
.Terms-modal .ant-btn-primary:active {
  border-color: #147F74;
  background-color: #147F74;
  color: #FFF;
}
@media only screen and (min-width:992px) {
  .Terms-modal {
    width: 520px !important;
  }
}
.Wall-title {
  font-weight: 600;
  color: #273941;
  font-size: 2rem;
  display: initial;
}
@media only screen and (min-width:992px) {
  .Wall-title {
    font-size: 50px;
  }
}
.Wall-filters {
  margin-top: 10px;
}
.Wall-filters .InitiativeFilter-search input.ant-input {
  height: 100%;
}
.Wall-firstcard {
  margin-top: -120px;
  position: relative;
}
.Wall-emptytext {
  text-align: center;
}
.no-initiative.ant-card-bordered {
  margin: 10px;
  padding: 10px;
}
.no-initiative {
  padding: 40px 10px 10px 10px;
  display: flex;
  flex-direction: column;
  text-align: -webkit-center;
}
.no-initiative svg {
  position: relative;
  top: 7px;
}
.no-initiative .empty {
  margin: 10px;
}
.no-initiative .empty .ant-empty-image {
  height: 150px;
}
.no-initiative .empty .ant-empty-description {
  margin-top: 60px;
  font-size: 2rem;
  font-weight: 600;
  color: #273941;
}
.no-initiative .text {
  font-size: 1.2rem;
  color: #7B8989;
}
@media only screen and (min-width:992px) {
  .ant-drawer-content-wrapper {
    width: 350px !important;
  }
}
.initiativeBanner {
  width: 100%;
  margin-bottom: 5px;
}
.initiativeContainer {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(285px, 1fr));
  grid-column-gap: 6px;
  grid-row-gap: 24px;
}
.initiativeContainer.pastInitiatives .initiative-card {
  opacity: 0.5;
}
.ant-divider-horizontal.ant-divider-with-text.initiativeDivider {
  margin-top: 50px;
  margin-bottom: 50px;
  font-size: 1.5em;
  font-weight: bold;
}
