.Initiative-taxonomy-filter {
  margin: 20px 5px 35px;
}
.Initiative-taxonomy-filter > h4 {
  font-size: 17px;
}
.Initiative-taxonomy-filter:first-child {
  margin-top: 5px;
}
.Initiative-taxonomy-filter:last-child {
  margin-bottom: 15px;
}
.Initiative-taxonomy-filter-sdg-logo {
  height: 20px;
  width: 20px;
  display: inline-block;
  padding-top: 0;
  margin-top: 5px;
  margin-bottom: -5px;
  margin-right: 10px;
}
.Initiative-taxonomy-filter .ant-checkbox-wrapper {
  margin-bottom: 5px;
}
.Initiative-taxonomy-filter .ant-checkbox-wrapper .ant-checkbox-input:hover,
.Initiative-taxonomy-filter .ant-checkbox-wrapper .ant-checkbox-input:focus {
  height: initial;
}
.Initiative-taxonomy-filter .ant-checkbox-wrapper + .ant-checkbox-wrapper {
  margin-left: 25px;
}
.InitiativeFilter {
  color: red !important;
}
.InitiativeFilter-search {
  border: 2px solid #e5e8e8;
  background-color: #e5e8e8;
  border-radius: 5px;
}
.InitiativeFilter-search > * {
  background-color: #e5e8e8;
}
.InitiativeFilter-search > .ant-input-suffix {
  margin-right: 5px;
}
.InitiativeFilter-search > .ant-input-suffix > :last-child {
  display: none;
}
.InitiativeFilter-search:hover,
.InitiativeFilter-search:focus {
  border: 2px solid #147F74;
  border-radius: 5px;
  box-shadow: none;
}
.ant-drawer-content-wrapper {
  min-height: 90vh;
  width: 100vw;
}
.ant-drawer-content-wrapper .ant-drawer-content {
  border-radius: 10px 10px 0px 0px;
}
@media only screen and (min-width:992px) {
  .ant-drawer-content-wrapper .ant-drawer-content {
    border-radius: 0px;
  }
}
.filterButton {
  color: #147F74;
  border: 2px solid #147F74;
  border-radius: 25px;
  margin: 10px 5px 10px 0px;
}
.filterButton:hover,
.filterButton:active,
.filterButton:focus {
  box-shadow: none;
  border-color: #147F74;
  background-color: #147F74;
  color: white;
}
.filterActive {
  box-shadow: none;
  border-color: #147F74;
  background-color: #147F74;
  color: white;
}
.ant-checkbox-input:hover,
.ant-checkbox-input:focus {
  box-shadow: none;
  height: 500px;
  border-color: #147F74;
}
.ant-checkbox-inner {
  border: 2px solid #147F74;
  border-radius: 6px;
  height: 24px;
  width: 24px;
}
.ant-checkbox-inner::after {
  height: 13px;
}
.ant-checkbox-checked .ant-checkbox-inner {
  border-color: #147F74;
  background-color: #147F74;
}
