.secondary-button {
  height: 50px;
  font-size: 1rem;
  font-weight: 600;
  border-radius: 6px;
  border-width: 2px;
  color: #000000a6;
}
.secondary-button:hover,
.secondary-button:active,
.secondary-button:focus {
  border-color: #d9d9d9;
  color: #000000a6;
}
@media only screen and (min-width:992px) {
  .header {
    padding: 0px 5px;
  }
}
.header .image-section .initiative-rating .rating {
  position: absolute;
  top: 20px;
  right: 20px;
}
.header .image-section .initiative-rating .rating svg {
  position: relative;
  top: 0px;
  left: 60px;
  z-index: 1;
  font-size: 12px;
}
.header .image-section .initiative-rating .rating .ant-badge-count {
  box-shadow: none;
  -webkit-box-shadow: none;
}
.header .image-section .initiative-rating .rating span > sup {
  background-color: #e8bb03;
  border-radius: 50px;
  padding: 8px 29px 27px 14px;
  color: #273941;
  font-size: 1.2em;
  font-weight: 600;
}
.header .image-section img {
  object-fit: contain;
  width: 100%;
}
.header .title-section {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #11181b;
  padding: 20px;
}
.header .title-section .title {
  color: #FFF;
  line-height: 1.2;
  font-size: 2rem;
  font-weight: 500;
}
.header .title-section .bottom-section {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 60px;
}
.header .title-section .bottom-section .sdgs {
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
}
.header .title-section .bottom-section .sdgs .logo {
  display: inline;
}
.header .title-section .bottom-section .sdgs .logo img {
  width: 40px;
  margin-right: 10px;
}
.header .title-section .bottom-section .organization-rating .Avatar {
  padding: 20px;
  width: 40px;
}
.header .title-section .bottom-section .organization-rating .Avatar img {
  max-width: 40px;
  max-height: 40px;
}
.header .title-section .bottom-section .organization-rating .rating {
  position: relative;
  bottom: 22px;
  right: 50px;
}
.header .title-section .bottom-section .organization-rating .rating svg {
  position: relative;
  top: 0px;
  left: 41px;
  z-index: 1;
  font-size: 12px;
}
.header .title-section .bottom-section .organization-rating .rating .ant-badge-count {
  box-shadow: none;
  -webkit-box-shadow: none;
}
.header .title-section .bottom-section .organization-rating .rating span > sup {
  background-color: #e8bb03;
  border-radius: 50px;
  padding: 2px 20px 21px 5px;
  color: #273941;
  font-size: 15px;
  font-weight: 600;
}
.header .ant-scroll-number-only {
  display: initial;
}
.header .ant-scroll-number-only .ant-scroll-number-only-unit {
  display: none;
}
.header .ant-scroll-number-only .ant-scroll-number-only-unit.current {
  display: initial;
}
.initiative-details {
  padding: 0px 30px 0px 30px;
}
@media only screen and (min-width:992px) {
  .initiative-details {
    padding: 0px 60px 0px 60px;
  }
}
.initiative-details .main-section .ant-alert {
  margin-top: 30px;
}
@media only screen and (min-width:992px) {
  .initiative-details .main-section {
    padding-right: 60px;
  }
}
@media only screen and (min-width:992px) {
  .initiative-details .side-section {
    padding-left: 40px;
  }
}
.initiative-details .title {
  font-weight: 500;
  color: #7b8989;
  font-size: 17px;
  display: block;
}
.initiative-details .action-description {
  margin-top: 20px;
  margin-bottom: 10px;
  text-align: left;
  white-space: pre-line;
  word-break: break-word;
}
.initiative-details .action-description .description {
  margin-top: 20px;
  display: block;
  overflow: hidden;
  color: #000000a6;
}
.initiative-details .action-description .description-showMore {
  margin-top: 20px;
  display: block;
  overflow: hidden;
  color: #000000a6;
  max-height: 300px;
}
.initiative-details .action-description .showMore {
  margin-top: 10px;
  display: flex;
  position: relative;
  justify-content: center;
}
.initiative-details .action-description .showMore .shadow {
  position: absolute;
  top: -68px;
  width: 100%;
  height: 64px;
  background-image: linear-gradient(-180deg, #fcfcfc00 0%, #fcfcfc 100%);
  margin: 0px;
}
.initiative-details .action-description .showMore button {
  font-size: 0.8em;
  height: initial;
  padding: 7px;
  border: 1px solid #797878;
  font-weight: 500;
}
.initiative-details .skills .items {
  font-size: 15px;
}
.initiative-details .categories .items {
  font-size: 15px;
}
.initiative-details .restrictions {
  width: 100%;
}
.initiative-details .restrictions .item {
  display: flex;
  justify-content: space-between;
  font-size: 15px;
}
.initiative-details .indicators {
  width: 100%;
}
.initiative-details .indicators .item {
  display: flex;
  justify-content: space-between;
  font-size: 15px;
}
.initiative-details .manager-approval {
  width: 100%;
}
.initiative-details .manager-approval .item {
  display: flex;
  justify-content: space-between;
  font-size: 15px;
}
.initiative-details .program {
  text-align: left;
}
@media only screen and (min-width:992px) {
  .initiative-details .program {
    margin-top: 20px;
  }
}
.initiative-details .program .info {
  display: block;
  font-weight: bold;
}
.initiative-details .points {
  text-align: left;
}
@media only screen and (min-width:992px) {
  .initiative-details .points {
    margin-top: 20px;
  }
}
.initiative-details .points .info {
  display: block;
  font-size: 42px;
  font-weight: bold;
}
.initiative-details .when {
  text-align: left;
}
@media only screen and (min-width:992px) {
  .initiative-details .when {
    margin-top: 20px;
  }
}
.initiative-details .when .date {
  display: block;
  font-size: 15px;
}
.initiative-details .tags {
  margin-top: 20px;
}
.initiative-details .tags .tag {
  border-radius: 3px;
  backdrop-filter: blur(17px);
  height: 25px;
  padding: 4px 10px;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.12px;
  text-align: right;
  margin-top: 5px;
}
.initiative-details .submitter {
  margin-top: 20px;
  max-width: 50%;
}
.initiative-details .submitter .Avatar {
  margin-top: 5px;
  margin-bottom: 5px;
}
.initiative-details .submitter .subtitle {
  width: 100%;
  text-align: center;
  display: inline-block;
}
.initiative-details .address {
  margin-top: 20px;
}
.initiative-details .feedback {
  margin-top: 20px;
}
.initiative-details .feedback a {
  font-size: 1.3em;
}
.initiative-details .rate {
  margin-top: 20px;
}
.initiative-details .rate .rating {
  color: rgba(0, 0, 0, 0.45);
}
.initiative-details .initiative-buttons {
  display: flex;
  justify-content: center;
}
.initiative-details .initiative-buttons > a button {
  width: 100%;
}
.initiative-details .initiative-buttons > .ant-btn,
.initiative-details .initiative-buttons > a {
  flex: 0.5;
  margin: 35px 5px 5px 5px;
}
.initiative-details .initiative-buttons > .ant-btn svg,
.initiative-details .initiative-buttons > a svg {
  margin-right: 5px;
}
.donation-details {
  padding: 0px 30px 0px 30px;
}
@media only screen and (min-width:992px) {
  .donation-details {
    padding: 0px 60px 0px 60px;
  }
}
.donation-details .main-section .ant-alert {
  margin-top: 30px;
}
@media only screen and (min-width:992px) {
  .donation-details .main-section {
    padding-right: 60px;
  }
}
@media only screen and (min-width:992px) {
  .donation-details .side-section {
    padding-left: 40px;
  }
}
.donation-details .title {
  font-weight: 500;
  color: #7b8989;
  font-size: 17px;
  display: block;
}
.donation-details .action-description {
  margin-top: 20px;
  margin-bottom: 10px;
  text-align: left;
  white-space: pre-line;
  word-break: break-word;
}
.donation-details .action-description .description {
  margin-top: 20px;
  display: block;
  overflow: hidden;
  color: #000000a6;
}
.donation-details .action-description .description-showMore {
  margin-top: 20px;
  display: block;
  overflow: hidden;
  color: #000000a6;
  max-height: 300px;
}
.donation-details .action-description .showMore {
  margin-top: 10px;
  display: flex;
  position: relative;
  justify-content: center;
}
.donation-details .action-description .showMore .shadow {
  position: absolute;
  top: -68px;
  width: 100%;
  height: 64px;
  background-image: linear-gradient(-180deg, #fcfcfc00 0%, #fcfcfc 100%);
  margin: 0px;
}
.donation-details .action-description .showMore button {
  font-size: 0.8em;
  height: initial;
  padding: 7px;
  border: 1px solid #797878;
  font-weight: 500;
}
.donation-details .skills .items {
  font-size: 15px;
}
.donation-details .categories .items {
  font-size: 15px;
}
.donation-details .restrictions {
  width: 100%;
}
.donation-details .restrictions .item {
  display: flex;
  justify-content: space-between;
  font-size: 15px;
}
.donation-details .indicators {
  width: 100%;
}
.donation-details .indicators .item {
  display: flex;
  justify-content: space-between;
  font-size: 15px;
}
.donation-details .manager-approval {
  width: 100%;
}
.donation-details .manager-approval .item {
  display: flex;
  justify-content: space-between;
  font-size: 15px;
}
.donation-details .program {
  text-align: left;
}
@media only screen and (min-width:992px) {
  .donation-details .program {
    margin-top: 20px;
  }
}
.donation-details .program .info {
  display: block;
  font-weight: bold;
}
.donation-details .points {
  text-align: left;
}
@media only screen and (min-width:992px) {
  .donation-details .points {
    margin-top: 20px;
  }
}
.donation-details .points .info {
  display: block;
  font-size: 42px;
  font-weight: bold;
}
.donation-details .when {
  text-align: left;
}
@media only screen and (min-width:992px) {
  .donation-details .when {
    margin-top: 20px;
  }
}
.donation-details .when .date {
  display: block;
  font-size: 15px;
}
.donation-details .tags {
  margin-top: 20px;
}
.donation-details .tags .tag {
  border-radius: 3px;
  backdrop-filter: blur(17px);
  height: 25px;
  padding: 4px 10px;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.12px;
  text-align: right;
  margin-top: 5px;
}
.donation-details .submitter {
  margin-top: 20px;
  max-width: 50%;
}
.donation-details .submitter .Avatar {
  margin-top: 5px;
  margin-bottom: 5px;
}
.donation-details .submitter .subtitle {
  width: 100%;
  text-align: center;
  display: inline-block;
}
.donation-details .address {
  margin-top: 20px;
}
.donation-details .feedback {
  margin-top: 20px;
}
.donation-details .feedback a {
  font-size: 1.3em;
}
.donation-details .rate {
  margin-top: 20px;
}
.donation-details .rate .rating {
  color: rgba(0, 0, 0, 0.45);
}
.donation-details .initiative-buttons {
  display: flex;
  justify-content: center;
}
.donation-details .initiative-buttons > a button {
  width: 100%;
}
.donation-details .initiative-buttons > .ant-btn,
.donation-details .initiative-buttons > a {
  flex: 0.5;
  margin: 35px 5px 5px 5px;
}
.donation-details .initiative-buttons > .ant-btn svg,
.donation-details .initiative-buttons > a svg {
  margin-right: 5px;
}
.donation-details .donation-section {
  padding-top: 30px;
}
.donation-details .donation-section .status {
  text-align: right;
  font-size: 15px;
}
.donation-details .donation-section .progress-bar .ant-progress-bg {
  color: #147F74;
  background-color: #147F74;
}
.donation-details .donation-section .donation-details {
  padding: 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.donation-details .donation-section .donation-details .details {
  margin-top: 10px;
}
.donation-details .donation-section .donation-details .details .type {
  font-size: 0.8em;
  color: #273941;
  font-weight: 600;
}
@media only screen and (min-width:992px) {
  .donation-details .donation-section .donation-details .details .type {
    font-size: 1em;
  }
}
.donation-details .donation-section .donation-details .details .type .main {
  color: #147F74;
}
.donation-details .donation-section .donation-details .details .value {
  font-size: 13px;
}
.donation-details .donation-section .tax-info img {
  display: inline;
  margin-right: 10px;
}
.donation-details .donation-section .tax-info p {
  display: inline;
}
.donation-details .donation-section .allowed-sources {
  margin-top: 20px;
}
.donation-details .donation-section .allowed-sources .title {
  font-size: 15px;
}
.donation-details .donation-section .allowed-sources img {
  display: inline;
  margin: 15px 12px 40px 0px;
}
@media only screen and (min-width:992px) {
  .donation-details .donation-section .allowed-sources img {
    margin: 15px 30px 40px 0px;
  }
}
@media only screen and (min-width:992px) {
  .donation-details .when {
    margin-top: 30px;
  }
}
.donation-details .donation-buttons {
  display: flex;
  justify-content: center;
}
.donation-details .donation-buttons .ant-btn {
  flex: 0.5;
  margin: 35px 5px 5px 5px;
}
.donation-details .donation-buttons .ant-btn svg {
  margin-right: 5px;
}
.Contact-drawer .ant-drawer-content {
  bottom: 0;
  position: absolute;
}
.Contact-drawer .ant-drawer-content .ant-drawer-header svg {
  color: #000;
}
.Contact-drawer .ant-drawer-content .ant-drawer-header .ant-drawer-title {
  font-weight: bold;
  font-size: 1.2rem;
}
.Contact-drawer .ant-drawer-content .ant-drawer-body > .ant-row {
  color: #7b8989;
  margin-bottom: 1rem;
}
.Contact-drawer .ant-drawer-content .ant-drawer-body > .ant-row button {
  width: 100%;
}
.Contact-drawer .ant-drawer-content .ant-drawer-body > .ant-row .ant-col {
  margin-top: 0.2rem;
}
.Contact-drawer .ant-drawer-content .ant-drawer-body > .ant-row .ant-col .ant-input-wrapper input,
.Contact-drawer .ant-drawer-content .ant-drawer-body > .ant-row .ant-col .ant-input-wrapper span {
  border: 2px solid #d9d9d9;
  border-radius: 5px;
}
.Contact-drawer .ant-drawer-content .ant-drawer-body > .ant-row .ant-col .ant-input-wrapper input {
  background-color: #FFF;
  border-right: none;
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  color: #000;
  cursor: pointer;
  height: 40px;
}
.Contact-drawer .ant-drawer-content .ant-drawer-body > .ant-row .ant-col .ant-input-wrapper span {
  background-color: #147F74;
  color: #FFF;
  border-color: #147F74;
  border-left: none;
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  width: 30%;
}
.InitiativeDetail-collaborators .ant-list-item-meta-description {
  margin-top: 10px;
  margin-left: -50px;
}
.InitiativeDetail-organizer {
  margin-top: 20px;
}
.InitiativeDetail-organizer .ant-list-item-meta-description {
  margin-top: 10px;
  margin-left: -50px;
}
.AttachmentDocuments .uploadSuccess {
  margin-bottom: 5px;
}
.AttachmentImages .uploadSuccess {
  margin-bottom: 5px;
}
.AttachmentImages .ant-avatar {
  cursor: pointer;
}
.AttachmentImages .ant-avatar:hover {
  filter: opacity(0.8);
}
.InitiativeDetail-image-viewer .ant-modal-body {
  text-align: center;
}
.InitiativeDetail-image-viewer img {
  max-height: 100%;
  max-width: 100%;
}
.sdgs-popover-content .logo {
  display: inline;
}
.sdgs-popover-content .logo img {
  width: 40px;
  margin-right: 10px;
}
.embedded .initiative-details,
.embedded .donation-details {
  padding-bottom: 120px;
}
.embedded .initiative-buttons,
.embedded .donation-buttons {
  position: fixed;
  width: 90%;
  bottom: 20px;
}
@media only screen and (min-width:768px) {
  .embedded .initiative-buttons,
  .embedded .donation-buttons {
    width: 92%;
    bottom: 45px;
  }
}
@media only screen and (min-width:992px) {
  .embedded .initiative-buttons,
  .embedded .donation-buttons {
    width: 75%;
  }
}
